import { z } from 'zod';
import { RaffleGroupEarlyBirdLaunchSocialMediaMilestonesSchema } from './raffle_group_early_bird_launch_social_media_milestones.schema';
import { RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchema } from './raffle_group_early_bird_launch_preregistration_milestones.schema';

const RaffleGroupEarlyBirdLaunchRaffleSchema = z.object({
	currency_symbol: z.string(),
	prize_pool: z.number().int(),
	unlock_prize_pool: z.number().int(),
});

const RaffleGroupEarlyBirdLaunchRafflesSchema = z
	.object({
		gold_tier: RaffleGroupEarlyBirdLaunchRaffleSchema,
		prismatic_tier: RaffleGroupEarlyBirdLaunchRaffleSchema,
		silver_tier: RaffleGroupEarlyBirdLaunchRaffleSchema,
	})
	.strict();

export const RaffleGroupEarlyBirdLaunchSchema = z
	.object({
		raffles: RaffleGroupEarlyBirdLaunchRafflesSchema,
		preregistration_milestones:
			RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchema,
		social_media_milestones:
			RaffleGroupEarlyBirdLaunchSocialMediaMilestonesSchema,
	})
	.strict();

export type RaffleGroupEarlyBirdLaunchSchemaType = z.infer<
	typeof RaffleGroupEarlyBirdLaunchSchema
>;
