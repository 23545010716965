import React from 'react';
import { RaffleGroupRoutes } from 'router/constants/RaffleGroup.routes';
import RaffleGroupEarlyBirdLaunch from 'views/Raffles/RaffleGroups/EarlyBirdLaunch/RaffleGroupEarlyBirdLaunch';
import RaffleGroupEarlyBirdLaunchDetails from 'views/Raffles/RaffleGroups/EarlyBirdLaunch/RaffleGroupEarlyBirdLaunchDetails';

export const raffleGroupRoutes = [
	{
		name: 'Raffle Group Early Bird Launch',
		path: RaffleGroupRoutes.RaffleGroupEarlyBirdLaunch,
		element: <RaffleGroupEarlyBirdLaunch />,
	},
	{
		name: 'Raffle Group Early Bird Launch Details',
		path: RaffleGroupRoutes.RaffleGroupEarlyBirdLaunchDetails,
		element: <RaffleGroupEarlyBirdLaunchDetails />,
	},
];
