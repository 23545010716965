import {
	useNavigate as _useNavigate,
	NavigateOptions,
	To,
} from 'react-router-dom';

export const useNavigate = () => {
	const nav = _useNavigate();

	const navigate = (to: To, options?: NavigateOptions) => {
		nav(to, options);
	};

	return navigate;
};
