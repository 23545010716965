// import React from 'react';
import React, { useState, useEffect } from 'react';
import {
	ResponsiveContainer,
	ViewContainer,
	Space,
	Box,
	Stack,
	Group,
	Anchor,
	Text,
	Card,
} from 'components';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from 'lib/firebase';
import { FirestoreCollectionKey } from 'enums/firebase.enum';
import { JobsSchema, JobsSchemaType } from 'schemas/careers/jobs.schema';

const Careers = () => {
	const [jobs, setJobs] = useState<JobsSchemaType>([]);

	const fetchJobs = async () => {
		const firestoreCollection = collection(
			firestore,
			FirestoreCollectionKey.JOBS
		);
		const firestoreSnapshot = await getDocs(firestoreCollection);
		const data = firestoreSnapshot.docs.map((doc) => doc.data());

		const schemaResult = JobsSchema.safeParse(data);
		if (schemaResult.success) {
			setJobs(schemaResult.data);
		} else {
			// TODO: Log firebase realtime database retrieve error
		}
	};

	useEffect(() => {
		fetchJobs();
	}, []);

	return (
		<ViewContainer title="Careers">
			<Box className="md:py-[100px] py-[60px] text-center bg-primary-brilliant-blue">
				<ResponsiveContainer>
					<Text className="font-bold text-primary-white md:text-5xl text-3xl">
						Join Our Team
					</Text>
					<Space className="md:h-[20px] h-[10px]" />
					<Text className="md:text-2xl text-md text-primary-white">
						Build your future with us and make an impact!
					</Text>
				</ResponsiveContainer>
			</Box>
			<Space h="50px" />
			<ResponsiveContainer>
				<Text className="md:text-3xl text-xl font-bold">Open Positions</Text>
				<Space h="xl" />
				<Stack spacing="lg">
					{jobs.map((job, index) => (
						<Card key={index} shadow="sm" p="lg" radius="md">
							<Text className="md:text-2xl text-lg font-semibold">
								{job.title}
							</Text>
							<Space h="md" />
							<Text className="md:text-sm text-xs text-[#555]">
								Location: {job.location}
							</Text>
							<Space h="xs" />
							<Text className="md:text-sm text-xs text-[#555]">
								{job.description}
							</Text>
							<Space h="lg" />
							<Group className="md:justify-start justify-center">
								<Anchor
									href={job.url ?? ''}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Box className="bg-primary-old-gold rounded-3xl py-2 px-4 drop-shadow-lg">
										<Text className="font-semibold text-secondary-black md:text-md text-sm text-center">
											Apply Now
										</Text>
									</Box>
								</Anchor>
							</Group>
						</Card>
					))}
				</Stack>
			</ResponsiveContainer>
			<Space className="h-28" />
		</ViewContainer>
	);
};

export default Careers;
