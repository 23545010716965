import { z } from 'zod';

export const GetRaffleGroupsParamsSchema = z.object({
	limit: z.number().int().positive(),
	currency_code: z.string(),
});
export type GetRaffleGroupsParamsSchemaType = z.infer<
	typeof GetRaffleGroupsParamsSchema
>;

const RaffleGroupCurrencySchema = z.object({
	code: z.string(),
	symbol: z.string(),
});
const RaffleSchema = z.object({
	code: z.string(),
	raffle_tier: z.string(),
	prize_pool: z.number().int().nonnegative(),
});
const RaffleGroupSchema = z.object({
	code: z.string(),
	name: z.string(),
	description: z.string().nullable(),
	image_url: z.string().nullable(),
	website_url: z.string().nullable(),
	currency: RaffleGroupCurrencySchema,
	raffles: z.array(RaffleSchema),
});
export const GetRaffleGroupsSchema = z.array(RaffleGroupSchema);
export type GetRaffleGroupsSchemaType = z.infer<typeof GetRaffleGroupsSchema>;
