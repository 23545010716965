import axios from 'axios';

export const apiRoutes: Record<'webApi', Record<string, string>> = {
	webApi: {
		local: 'http://localhost:3031/api/v1',
		staging: 'https://staging-web.tcgunite.co/api/v1',
		production: 'https://web.tcgunite.co/api/v1',
	},
};

export const webApi = axios.create({
	baseURL: apiRoutes.webApi[process.env.REACT_APP_DEPLOYMENT_ENV || 'local'],
});
