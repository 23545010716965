import React, { useState } from 'react';
import {
	Box,
	Group,
	Stack,
	Text,
	Space,
	UnstyledButton,
	Collapse,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Icon from 'assets/icons';

const RaffleFaq = () => {
	const { t } = useTranslation();

	const [faqIndex, setFaqIndex] = useState<number | null>(null);

	// TODO: Get FAQs from server
	const faqs = [
		{
			title: t('growing_raffle_prizes'),
			description: t(
				'our_raffle_prizes_will_be_fueled_by_ad_revenue_and_in_app_purchases'
			),
		},
		{
			title: t('transparency_and_accountability'),
			description: t('we_will_provide_regular_updates_on_the_community_funds'),
		},
		{
			title: t('community_driven_initiatives'),
			description: t(
				'we_plan_to_allocate_community_funds_to_support_grassroots_tcg_initiatives'
			),
		},
		{
			title: t('user_feedback_and_engagement'),
			description: t('we_value_your_feedback_and_suggestions'),
		},
	];
	return (
		<Box>
			<Text className="text-primary-brilliant-blue md:text-6xl text-3xl font-['Bungee'] text-center">
				{t('frequently_asked_questions')}
			</Text>
			<Space className="md:h-16 h-8" />
			<Stack className="xl:w-2/3 w-full mx-auto my-0 gap-4">
				{faqs.map((faq, index) => {
					return (
						<Group
							key={index}
							className="border-b border-secondary-greyish-blue align-items-start flex-nowrap md:gap-8 gap-6"
						>
							<UnstyledButton
								onClick={() => setFaqIndex(index === faqIndex ? null : index)}
							>
								<Icon
									name={index === faqIndex ? 'remove' : 'add'}
									className="text-secondary-dark-blue md:h-8 md:w-8 h-6 w-6 stroke-0"
								/>
							</UnstyledButton>
							<Stack>
								<Text className="md:text-xl text-md text-left font-semibold text-secondary-dark-blue">
									{faq.title}
								</Text>
								<Collapse
									in={faqIndex === index}
									className="md:text-md text-sm"
								>
									{faq.description}
								</Collapse>
								<Space h="md" />
							</Stack>
						</Group>
					);
				})}
			</Stack>
		</Box>
	);
};

export default RaffleFaq;
