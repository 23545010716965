import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Flex,
	UnstyledButton,
	Image,
	Stack,
	Group,
	Text,
	Space,
	ResponsiveContainer,
} from 'components';
import { useNavigate } from 'lib/navigate';
import { useAtom } from 'jotai';
import { getFeaturedRaffleGroup } from 'atoms/raffle_groups/getRaffleGroupsAtom';
import { formatPrice } from 'utils/formatter';

import smallAllTicketsImage from 'views/assets/raffle/small_all_tickets.png';
import raffleGroupMobileImage from 'views/assets/raffle/raffle_group_mobile.png';
import raffleGroupWebImage from 'views/assets/raffle/raffle_group_web.png';
import emptyRafflePlaceholderImage from 'views/assets/raffle/empty_raffle_placeholder.png';
import { RaffleTier } from 'enums/raffle.enum';
import { mapSmallRaffleTierTicketImage } from 'mappers/mapSmallRaffleTierTicketImage';

const FeaturedRaffleGroup = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [{ data: raffle_group }] = useAtom(getFeaturedRaffleGroup);

	if (!raffle_group)
		return (
			<>
				<Box className="bg-secondary-dark-blue md:h-[700px] h-[400px] w-full xl:py-24 md:py-16">
					<ResponsiveContainer className="relative h-full xl:block flex flex-col justify-center align-items-center">
						<Text className="text-secondary-white md:text-7xl text-4xl font-['Bungee'] xl:text-left text-center">
							{t('raffles')}
						</Text>
						<Space className="xl:hidden md:h-[40px] h-[70px]"></Space>
						<img
							src={emptyRafflePlaceholderImage}
							alt="Empty Raffle Placeholder"
							className="contain xl:h-[500px] md:h-[450px] h-[160px] xl:absolute xl:bottom-0 xl:right-0"
						/>
					</ResponsiveContainer>
				</Box>
			</>
		);

	const total_prize_pool = raffle_group.raffles.reduce(
		(prev, curr) => prev + curr.prize_pool,
		0
	);
	const prismatic_raffle = raffle_group.raffles.find(
		(raffle) => raffle.raffle_tier === RaffleTier.PRISMATIC
	);
	const gold_raffle = raffle_group.raffles.find(
		(raffle) => raffle.raffle_tier === RaffleTier.GOLD
	);
	const silver_raffle = raffle_group.raffles.find(
		(raffle) => raffle.raffle_tier === RaffleTier.SILVER
	);

	return (
		<ResponsiveContainer>
			<Space className="xl:h-28 md:h-20 h-10" />
			<UnstyledButton
				onClick={() => navigate(raffle_group.website_url ?? '')}
				className="w-full"
			>
				<Box className="bg-primary-white drop-shadow-md rounded-xl overflow-hidden">
					<Flex className="xl:flex-row flex-col">
						<Box
							className="xl:grow xl:basis-0"
							id="featured-raffle-group-image"
						>
							<Image
								src={raffleGroupWebImage}
								alt="Raffle Group"
								fit="cover"
								height="100%"
								className="md:block hidden h-full"
							/>
							<Image
								src={raffleGroupMobileImage}
								alt="Raffle Group"
								fit="cover"
								height="100%"
								className="md:hidden block"
							/>
						</Box>
						<Stack className="xl:grow xl:basis-0 justify-center md:gap-3 gap-1 md:m-6 m-3">
							<Text className="md:text-4xl text-md text-left font-semibold">
								{raffle_group.name}
							</Text>
							<Text className="md:text-lg text-[8px] text-left">
								{raffle_group.description}
							</Text>
							<Space h="xs" />
							<Group className="items-center md:gap-3 gap-1 align-items-center leading-none">
								{gold_raffle && prismatic_raffle && silver_raffle && (
									<>
										<Image
											src={smallAllTicketsImage}
											alt="Mascot"
											fit="contain"
											className="md:w-6 w-4"
										/>
										<Box className="bg-primary-brilliant-blue md:w-[5px] w-[3px] md:h-[5px] h-[3px] rounded-xs"></Box>
									</>
								)}
								{[prismatic_raffle, gold_raffle, silver_raffle]
									.filter((raffle) => !!raffle)
									.map((raffle, index) => {
										if (!raffle) return <></>;
										return (
											<Image
												key={index}
												src={mapSmallRaffleTierTicketImage(raffle.raffle_tier)}
												alt="Mascot"
												fit="contain"
												className="md:w-6 w-4"
											/>
										);
									})}
								<Box className="bg-primary-brilliant-blue md:w-[5px] w-[3px] md:h-[5px] h-[3px] rounded-xs"></Box>
								<Text className="md:text-lg text-[8px] text-primary-brilliant-blue">
									{t('current_total_prize_amount', {
										total_prize_pool: formatPrice(
											total_prize_pool,
											raffle_group.currency.symbol
										),
									})}
								</Text>
							</Group>
						</Stack>
					</Flex>
				</Box>
			</UnstyledButton>
		</ResponsiveContainer>
		// TODO: Redirect to generic raffle group if no website url
	);
};

export default FeaturedRaffleGroup;
