export function formatNumberWithCommas(x: number) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatPrice(
	price: number,
	currencySymbol: string,
	hasCents = true
) {
	return (
		currencySymbol +
		(price / 100)
			.toFixed(hasCents ? 2 : 0)
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	);
}

export function formatCapitalizeEveryFirstCharacter(str: string) {
	const splitStr = str.toLowerCase().split(' ');
	for (let i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] =
			splitStr[i].charAt(0).toUpperCase() +
			splitStr[i].substring(1).toLowerCase();
	}
	// Directly return the joined string
	return splitStr.join(' ');
}

export function formatCapitalizeFirstCharacter(str: string) {
	return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
}
