import {
	Box,
	ViewContainer,
	Text,
	Breadcrumbs,
	Anchor,
	Image,
	ResponsiveContainer,
	Space,
	UnstyledButton,
} from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import backdropMobileImage from 'views/assets/raffle_group/early_bird_launch/early_bird_backdrop_mobile.png';
import backdropTabletImage from 'views/assets/raffle_group/early_bird_launch/early_bird_backdrop_tablet.png';
import backdropDesktopImage from 'views/assets/raffle_group/early_bird_launch/early_bird_backdrop_desktop.png';
import earlyBirdHeroImage from 'views/assets/raffle_group/early_bird_launch/early_bird_hero.png';
import RaffleGroupEarlyBirdLaunchTitlePill from './components/RaffleGroupEarlyBirdLaunchTitlePill';
import { formatCapitalizeEveryFirstCharacter } from 'utils/formatter';
import Icon from 'assets/icons';
import RaffleGroupEarlyBirdLaunchPrizeSocialMediaMilestones from './components/RaffleGroupEarlyBirdLaunchSocialMediaMilestones';
import RaffleGroupEarlyBirdLaunchPrizePreregistrationMilestones from './components/RaffleGroupEarlyBirdLaunchPreregistrationMilestones';
import { RaffleRoutes } from 'router/constants/Raffle.routes';
import { useNavigate } from 'lib/navigate';
import { RaffleGroupRoutes } from 'router/constants/RaffleGroup.routes';
import RaffleGroupEarlyBirdLaunchPrizes from './components/RaffleGroupEarlyBirdLaunchPrizes';
import ExploreMore from 'views/Raffles/components/ExploreMore';

const RaffleGroupEarlyBirdLaunch = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// TODO: Breadcrumbs for raffle details
	const breadcrumbs = [
		{ title: 'Raffles', href: RaffleRoutes.Raffles },
		{ title: 'Get in Early Get Big Rewards', href: '#' },
	];

	return (
		<ViewContainer title="Home">
			<Box className="relative bg-background-blue">
				<img
					src={backdropDesktopImage}
					alt="Backdrop"
					className="absolute top-0 bottom-0 h-full w-full z-1 object-cover object-top xl:block hidden"
				/>
				<img
					src={backdropTabletImage}
					alt="Backdrop"
					className="absolute top-0 bottom-0 h-full w-full z-1 object-cover object-top xl:hidden md:block hidden"
				/>
				<img
					src={backdropMobileImage}
					alt="Backdrop"
					className="absolute top-0 bottom-0 h-full w-full z-1 object-cover object-top md:hidden block"
				/>
				<Box className="z-10 relative md:py-12 py-8">
					<ResponsiveContainer>
						<Breadcrumbs
							className="text-primary-brilliant-blue xl:text-lg md:text-sm text-xs text-center"
							id="raffle-details-breadcrumb"
						>
							{breadcrumbs.map((item, index) => {
								return (
									<Anchor
										href={item.href}
										key={index}
										className="text-primary-brilliant-blue"
									>
										{item.title}
									</Anchor>
								);
							})}
						</Breadcrumbs>
					</ResponsiveContainer>
					<Space className="md:h-20 h-10" />
					<ResponsiveContainer>
						<Box className="relative">
							<Image
								src={earlyBirdHeroImage}
								alt="Early Bird Launch Hero"
								fit="contain"
							/>
							<Space className="xl:h-auto md:h-[35px]" />
							<Box className="bg-gold rounded-full shadow-inner shadow-white xl:w-[700px] md:w-[450px] w-full xl:h-[80px] md:h-[70px] h-[50px] md:absolute bottom-0 flex justify-center align-items-center">
								<Text className="font-semibold text-primary-white md:text-2xl text-md text-center font-['Galindo']">
									{t('preregister_and_join_now')}
								</Text>
							</Box>
						</Box>
					</ResponsiveContainer>
				</Box>
				<ResponsiveContainer className="relative z-10 md:py-12 py-8">
					<RaffleGroupEarlyBirdLaunchTitlePill
						text={formatCapitalizeEveryFirstCharacter(t('current_prize_pool'))}
					/>
					<Space h="md" />
					<RaffleGroupEarlyBirdLaunchPrizes />
				</ResponsiveContainer>
				<ResponsiveContainer className="z-10 relative text-center">
					<UnstyledButton
						className="text-center"
						onClick={() =>
							navigate(RaffleGroupRoutes.RaffleGroupEarlyBirdLaunchDetails)
						}
					>
						<Icon name="doublearrowup" className="text-secondary-white" />
						<Space h="sm" />
						<Text className="text-primary-white md:text-md text-sm">
							{t('click_to_explore_whats_waiting_for_you')}
						</Text>
					</UnstyledButton>
				</ResponsiveContainer>
				<Space className="h-[60px]" />
				<ResponsiveContainer className="relative z-10">
					<RaffleGroupEarlyBirdLaunchTitlePill
						text={t('increase_the_raffles_value')}
					/>
					<Space h="md" />
					<RaffleGroupEarlyBirdLaunchPrizePreregistrationMilestones />
				</ResponsiveContainer>
				<Space h="md" />
				<ResponsiveContainer className="relative z-10">
					<RaffleGroupEarlyBirdLaunchPrizeSocialMediaMilestones />
				</ResponsiveContainer>
				<ResponsiveContainer className="relative z-10 py-12 mx-auto flex justify-center">
					<UnstyledButton
						onClick={() =>
							navigate(RaffleGroupRoutes.RaffleGroupEarlyBirdLaunchDetails)
						}
					>
						<Box className="bg-gold rounded-full shadow-inner shadow-white md:w-[700px] w-[220px] md:h-[90px] h-[55px] flex justify-center align-items-center">
							<Text className="font-semibold text-primary-white text-3xl text-center font-['Galindo'] md:block hidden">
								{t('read_more_about_early_bird_raffles')}
							</Text>
							<Text className="text-primary-white text-xl text-center font-['Galindo'] md:hidden block">
								{t('read_more')}
							</Text>
						</Box>
					</UnstyledButton>
				</ResponsiveContainer>
				<Space className="h-16" />
			</Box>
			<ResponsiveContainer className="py-14">
				<ExploreMore />
			</ResponsiveContainer>
		</ViewContainer>
	);
};

export default RaffleGroupEarlyBirdLaunch;
