import { z } from 'zod';

const RaffleGroupEarlyBirdLaunchSocialMediaMilestonePrizeSchema = z.object({
	currency_symbol: z.string(),
	number_of_target_users: z.number().int(),
	prize_pool: z.number().int().nonnegative(),
	raffle_tier: z.string(),
});

const RaffleGroupEarlyBirdLaunchSocialMediaMilestoneSchema = z
	.object({
		number_of_users: z.number().int().nonnegative(),
		social_media: z.string(),
		social_media_url: z.string(),
		prizes: z.array(RaffleGroupEarlyBirdLaunchSocialMediaMilestonePrizeSchema),
	})
	.strict();

export const RaffleGroupEarlyBirdLaunchSocialMediaMilestonesSchema = z.array(
	RaffleGroupEarlyBirdLaunchSocialMediaMilestoneSchema
);
export type RaffleGroupEarlyBirdLaunchSocialMediaMilestonesSchemaType = z.infer<
	typeof RaffleGroupEarlyBirdLaunchSocialMediaMilestonesSchema
>;
