import { webApi } from 'apis';
import {
	GetRaffleDetailsParamsSchemaType,
	GetRaffleDetailsSchema,
} from 'schemas/raffles/get_raffle_details.schema';

export async function getRaffleDetails(
	raffle_code: string | null,
	params: GetRaffleDetailsParamsSchemaType
) {
	if (!raffle_code) return null;

	const resp = await webApi
		.get(`/raffles/${raffle_code}`, {
			params,
		})
		.then(({ data }) => data);

	const result = GetRaffleDetailsSchema.safeParse(resp);
	if (!result.success) {
		// TODO: Log response parse error
		return null;
	}

	return resp;
}
