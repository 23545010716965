import { z } from 'zod';

export const GetRaffleDetailsParamsSchema = z.object({
	currency_code: z.string(),
});
export type GetRaffleDetailsParamsSchemaType = z.infer<
	typeof GetRaffleDetailsParamsSchema
>;

const GetRaffleDetailsCurrencySchema = z.object({
	code: z.string(),
	symbol: z.string(),
});

const GetRaffleDetailsPrizeWinnerSchema = z.object({
	tag: z.string(),
	name: z.string(),
	image_url: z.string().nullable(),
});

const GetRaffleDetailsPrizeSchema = z
	.object({
		name: z.string(),
		image_url: z.string().nullable(),
	})
	.extend({
		winner: GetRaffleDetailsPrizeWinnerSchema.nullable(),
	});

const GetRaffleDetailsPrizeGroupSchema = z
	.object({
		name: z.string(),
		prize_pool: z.number().int().nonnegative(),
	})
	.extend({
		prizes: z.array(GetRaffleDetailsPrizeSchema),
	});

export const GetRaffleDetailsSchema = z
	.object({
		code: z.string(),
		name: z.string(),
		raffle_tier: z.string(),
		prize_pool: z.number().int().nonnegative(),
		unlock_prize_pool: z.number().int().nonnegative().nullable(),
		banner_image_url: z.string().nullable(),
	})
	.extend({
		prize_groups: z.array(GetRaffleDetailsPrizeGroupSchema),
		currency: GetRaffleDetailsCurrencySchema,
	});
export type GetRaffleDetailsSchemaType = z.infer<typeof GetRaffleDetailsSchema>;
