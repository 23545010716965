import { RaffleTier } from 'enums/raffle.enum';
import smallPrismaticTicketImage from 'views/assets/raffle/small_prismatic_ticket.png';
import smallGoldTicketImage from 'views/assets/raffle/small_gold_ticket.png';
import smallSilverTicketImage from 'views/assets/raffle/small_silver_ticket.png';

export function mapSmallRaffleTierTicketImage(raffle_tier: string): string {
	switch (raffle_tier) {
		case RaffleTier.PRISMATIC:
			return smallPrismaticTicketImage;
		case RaffleTier.GOLD:
			return smallGoldTicketImage;
		case RaffleTier.SILVER:
			return smallSilverTicketImage;
		default:
			return smallSilverTicketImage;
	}
}
