import { z } from 'zod';

const JobSchema = z.object({
	description: z.string().nullable(),
	location: z.string().nullable(),
	title: z.string(),
	url: z.string().nullable(),
});

export const JobsSchema = z.array(JobSchema);
export type JobsSchemaType = z.infer<typeof JobsSchema>;
