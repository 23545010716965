import { RaffleTier } from 'enums/raffle.enum';
import silverRafflePlaceholderImage from 'views/assets/raffle/silver_raffle_placeholder.png';
import goldRafflePlaceholderImage from 'views/assets/raffle/gold_raffle_placeholder.png';
import prismaticRafflePlaceholderImage from 'views/assets/raffle/prismatic_raffle_placeholder.png';

export function mapRaffleTierPlaceholderImage(raffle_tier: string): string {
	switch (raffle_tier) {
		case RaffleTier.PRISMATIC:
			return prismaticRafflePlaceholderImage;
		case RaffleTier.GOLD:
			return goldRafflePlaceholderImage;
		case RaffleTier.SILVER:
			return silverRafflePlaceholderImage;
		default:
			return silverRafflePlaceholderImage;
	}
}
