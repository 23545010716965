import React from 'react';
import { Box, Group, Stack, Text, Image } from '@mantine/core';
import { formatCapitalizeFirstCharacter, formatPrice } from 'utils/formatter';
import { Trans } from 'react-i18next';
import mediumPrismaticTicketImage from '../../../../assets/raffle/medium_prismatic_ticket.png';
import mediumSilverTicketImage from '../../../../assets/raffle/medium_silver_ticket.png';
import mediumGoldTicketImage from '../../../../assets/raffle/medium_gold_ticket.png';
import { RaffleTier, RaffleTierEnum } from 'utils/enums/raffle.enum';

type RaffleGroupEarlyBirdLaunchPrizeProps = {
	prize_pool: number;
	unlock_prize_pool: number;
	currency_symbol: string;
	raffle_tier: RaffleTier;
};

const RaffleGroupEarlyBirdLaunchPrize = ({
	prize_pool,
	unlock_prize_pool,
	currency_symbol,
	raffle_tier,
}: RaffleGroupEarlyBirdLaunchPrizeProps) => {
	const ticketImage =
		raffle_tier === RaffleTierEnum.GOLD
			? mediumGoldTicketImage
			: raffle_tier === RaffleTierEnum.PRISMATIC
			? mediumPrismaticTicketImage
			: mediumSilverTicketImage;

	return (
		<Box className="bg-primary-white-200 xl:px-12 md:px-6 px-4 xl:py-20 md:py-10 py-6 max-w-none shadow-inner xl:rounded-[5rem] md:rounded-[3rem] rounded-[1.5rem] shadow-primary-white">
			<Stack className="flex-nowrap items-center">
				<Group className="md:gap-4 gap-6 justify-center">
					<Stack className="align-items-center gap-2">
						<Image
							src={ticketImage}
							alt="Silver Ticket"
							fit="contain"
							className="xl:w-20 md:w-12 w-10"
						/>
						<Text className="text-primary-white text-sm text-center font-['Galindo']">
							{formatCapitalizeFirstCharacter(raffle_tier) + ' Tier'}
						</Text>
					</Stack>
					<Text className="text-primary-white xl:text-7xl md:text-5xl text-2xl font-['Galindo'] text-center">
						{formatPrice(prize_pool, currency_symbol, false)}
					</Text>
				</Group>

				<Text
					className="text-primary-white xl:text-sm text-[10px] font-['Galindo'] text-center leading-none md:block hidden"
					id="raffle-group-early-bird-grand-launch-unlock-value"
				>
					<Trans
						i18nKey="unlock_up_to_value_in_value_of_prizes"
						values={{
							prize_pool: formatPrice(
								unlock_prize_pool,
								currency_symbol,
								false
							),
						}}
					/>
				</Text>
			</Stack>
		</Box>
	);
};

export default RaffleGroupEarlyBirdLaunchPrize;
