import React from 'react';
import { ResponsiveContainer, ViewContainer } from 'components';
import { t } from 'i18next';
import { Anchor, List, Space, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { HomeRoutes } from 'router/constants/Home.routes';

const PrivacyPolicy = () => {
	const navigate = useNavigate();

	return (
		<ViewContainer title="Privacy Policy">
			<ResponsiveContainer className="xl:max-w-[1000px] z-10 relative">
				<Text className="text-primary-brilliant-blue xl:text-7xl text-5xl font-['Bungee'] text-left">
					{t('privacy_policy')}
				</Text>
				<Space h="md" />
				<Text className="text-left text-sm">Effective: December 08, 2024</Text>
				<Text className="text-left text-sm">
					Last updated: December 08, 2024
				</Text>
				<Space h="md" />
				<Text>
					This Privacy Policy describes how TCGUnite (the &quot;Site&quot;,
					&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) collects, uses,
					and discloses your personal information when you visit, use our
					services, or make a purchase from tcgunite.co (the &quot;Site&quot;)
					or otherwise communicate with us (collectively, the
					&quot;Services&quot;). For purposes of this Privacy Policy,
					&quot;you&quot; and &quot;your&quot; means you as the user of the
					Services, whether you are a customer, website visitor, or another
					individual whose information we have collected pursuant to this
					Privacy Policy.
				</Text>
				<Space h="sm" />
				<Text>
					Please read this Privacy Policy carefully. By using and accessing any
					of the Services, you agree to the collection, use, and disclosure of
					your information as described in this Privacy Policy. If you do not
					agree to this Privacy Policy, please do not use or access any of the
					Services.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">Changes To The Privacy Policy</Text>
				<Space h="sm" />
				<Text>
					We may update this Privacy Policy from time to time, including to
					reflect changes to our practices or for other operational, legal, or
					regulatory reasons. We will post the revised Privacy Policy on the
					Site, update the &quot;Last updated&quot; date and take any other
					steps required by applicable law.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">
					How We Collect and Use Your Personal Information
				</Text>
				<Space h="sm" />
				<Text>
					To provide the Services, we collect and have collected over the past
					12 months personal information about you from a variety of sources, as
					set out below. The information that we collect and use varies
					depending on how you interact with us.
				</Text>
				<Space h="xs" />
				<Text>
					In addition to the specific uses set out below, we may use information
					we collect about you to communicate with you, provide the Services,
					comply with any applicable legal obligations, enforce any applicable
					terms of service, and to protect or defend the Services, our rights,
					and the rights of our users or others.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">
					What Personal Information We Collect
				</Text>
				<Space h="sm" />
				<Text>
					The types of personal information we obtain about you depends on how
					you interact with our Site and use our Services. When we use the term
					&quot;personal information&quot;, we are referring to information that
					identifies, relates to, describes or can be associated with you. The
					following sections describe the categories and specific types of
					personal information we collect.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">
					Information We Collect Directly from You
				</Text>
				<Space h="sm" />
				<Text>
					Information that you directly submit to us through our Services may
					include:
				</Text>
				<Space h="xs" />
				<List icon={<Text>&bull;</Text>}>
					<List.Item>
						<b>Basic contact details</b> including your name, address, phone
						number, email.
					</List.Item>
					<List.Item>
						<b>Order information</b> including your name, billing address,
						shipping address, payment confirmation, email address, phone number.
					</List.Item>
					<List.Item>
						<b>Account information</b> including your username, password,
						security questions.
					</List.Item>
					<List.Item>
						<b>Shopping information</b> including the items you view, put in
						your cart or add to your wishlist.
					</List.Item>
					<List.Item>
						<b>Customer support information</b> including the information you
						choose to include in communications with us, for example, when
						sending a message through the Services.
					</List.Item>
				</List>
				<Space h="40px" />
				<Text className="text-xl font-bold">
					Information We Collect through Cookies
				</Text>
				<Space h="sm" />
				<Text>
					We also automatically collect certain information about your
					interaction with the Services (&quot;Usage Data&quot;). To do this, we
					may use cookies, pixels and similar technologies
					(&quot;Cookies&quot;). Usage Data may include information about how
					you access and use our Site and your account, including device
					information, browser information, information about your network
					connection, your IP address and other information regarding your
					interaction with the Services.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">
					Information We Obtain from Third Parties
				</Text>
				<Space h="sm" />
				<Text>
					Finally, we may obtain information about you from third parties,
					including from vendors and service providers who may collect
					information on our behalf, such as:
				</Text>
				<Space h="xs" />
				<List icon={<Text>&bull;</Text>}>
					<List.Item>
						Our payment processors, who collect payment information (e.g., bank
						account, credit or debit card information, billing address) to
						process your payment in order to fulfill your orders and provide you
						with products or services you have requested, in order to perform
						our contract with you.
					</List.Item>
					<List.Item>
						When you visit our Site, open or click on emails we send you, or
						interact with our Services or advertisements, we, or third parties
						we work with, may automatically collect certain information using
						online tracking technologies such as pixels, web beacons, software
						developer kits, third-party libraries, and cookies.
					</List.Item>
					<List.Item>
						When you create an account using Google, Apple, or Facebook, we may
						receive information such as your name, email address, and profile
						picture. This information is used to create your account and to
						personalize your experience.
					</List.Item>
				</List>
				<Space h="xs" />
				<Text>
					Any information we obtain from third parties will be treated in
					accordance with this Privacy Policy. We are not responsible or liable
					for the accuracy of the information provided to us by third parties
					and are not responsible for any third party&apos;s policies or
					practices. For more information, see the section below, Third Party
					Websites and Links.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">
					How We Use Your Personal Information
				</Text>
				<Space h="sm" />
				<List icon={<Text>&bull;</Text>}>
					<List.Item>
						<b>Providing Products and Services.</b> We use your personal
						information to provide you with the Services in order to perform our
						contract with you, including to process your payments, fulfill your
						orders, to send notifications to you related to you account,
						purchases, returns, exchanges or other transactions, to create,
						maintain and otherwise manage your account, to arrange for shipping,
						facilitate any returns and exchanges and to enable you to post
						reviews.
					</List.Item>
					<List.Item>
						<b>Marketing and Advertising.</b> We use your personal information
						for marketing and promotional purposes, such as to send marketing,
						advertising and promotional communications by email, text message or
						postal mail, and to show you advertisements for products or
						services. This may include using your personal information to better
						tailor the Services and advertising on our Site and other websites.
					</List.Item>
					<List.Item>
						<b>Security and Fraud Prevention.</b> We use your personal
						information to detect, investigate or take action regarding possible
						fraudulent, illegal or malicious activity. If you choose to use the
						Services and register an account, you are responsible for keeping
						your account credentials safe. We highly recommend that you do not
						share your username, password, or other access details with anyone
						else. If you believe your account has been compromised, please
						contact us immediately.
					</List.Item>
					<List.Item>
						<b>Communicating with you.</b> We use your personal information to
						provide you with customer support and improve our Services. This is
						in our legitimate interests in order to be responsive to you, to
						provide effective services to you, and to maintain our business
						relationship with you.
					</List.Item>
				</List>
				<Space h="40px" />
				<Text className="text-xl font-bold mt-4">Cookies</Text>
				<Space h="sm" />
				<Text>
					Like many websites, we use Cookies on our Site. We use Cookies to
					power and improve our Site and our Services (including to remember
					your actions and preferences), to run analytics and better understand
					user interaction with the Services (in our legitimate interests to
					administer, improve and optimize the Services). We may also permit
					third parties and services providers to use Cookies on our Site to
					better tailor the services, products and advertising on our Site and
					other websites.
				</Text>
				<Space h="xs" />
				<Text>
					Most browsers automatically accept Cookies by default, but you can
					choose to set your browser to remove or reject Cookies through your
					browser controls. Please keep in mind that removing or blocking
					Cookies can negatively impact your user experience and may cause some
					of the Services, including certain features and general functionality,
					to work incorrectly or no longer be available. Additionally, blocking
					Cookies may not completely prevent how we share information with third
					parties such as our advertising partners.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">
					How We Disclose Personal Information
				</Text>
				<Space h="sm" />
				<Text>
					In certain circumstances, we may disclose your personal information to
					third parties for legitimate purposes subject to this Privacy Policy.
					Such circumstances may include:
				</Text>
				<Space h="xs" />
				<List icon={<Text>&bull;</Text>}>
					<List.Item>
						With vendors or other third parties who perform services on our
						behalf (e.g., IT management, payment processing, data analytics,
						customer support, cloud storage, fulfillment and shipping).
					</List.Item>
					<List.Item>
						With business and marketing partners, including Shopify, to provide
						services and advertise to you. Our business and marketing partners
						will use your information in accordance with their own privacy
						notices.
					</List.Item>
					<List.Item>
						When you direct, request us or otherwise consent to our disclosure
						of certain information to third parties, such as to ship you
						products or through your use of social media widgets or login
						integrations, with your consent.
					</List.Item>
					<List.Item>
						With our affiliates or otherwise within our corporate group, in our
						legitimate interests to run a successful business.
					</List.Item>
					<List.Item>
						In connection with a business transaction such as a merger or
						bankruptcy, to comply with any applicable legal obligations
						(including to respond to subpoenas, search warrants and similar
						requests), to enforce any applicable terms of service, and to
						protect or defend the Services, our rights, and the rights of our
						users or others.
					</List.Item>
				</List>
				<Space h="xs" />
				<Text>
					We have, in the past 12 months disclosed the following categories of
					personal information and sensitive personal information (denoted by *)
					about users for the purposes set out above in &quot;How we Collect and
					Use your Personal Information&quot; and &quot;How we Disclose Personal
					Information&quot;:
				</Text>
				<Space h="xs" />
				<table
					style={{
						border: '1px solid #ccc',
						borderCollapse: 'collapse',
						width: '100%',
					}}
				>
					<thead>
						<tr>
							<th
								style={{
									border: '1px solid #ccc',
									padding: '10px',
									backgroundColor: '#f9f9f9',
								}}
							>
								Category
							</th>
							<th
								style={{
									border: '1px solid #ccc',
									padding: '10px',
									backgroundColor: '#f9f9f9',
								}}
							>
								Categories of Recipients
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style={{ border: '1px solid #ccc', padding: '10px' }}>
								Identifiers such as basic contact details and certain order and
								account information
							</td>
							<td style={{ border: '1px solid #ccc', padding: '10px' }}>
								Vendors and third parties who perform services on our behalf
								(such as Internet service providers, payment processors,
								fulfillment partners, customer support partners and data
								analytics providers)
							</td>
						</tr>
						<tr>
							<td style={{ border: '1px solid #ccc', padding: '10px' }}>
								Commercial information such as order information, shopping
								information and customer support information
							</td>
							<td style={{ border: '1px solid #ccc', padding: '10px' }}>
								Business and marketing partners
							</td>
						</tr>
						<tr>
							<td style={{ border: '1px solid #ccc', padding: '10px' }}>
								Internet or other similar network activity, such as Usage Data
							</td>
							<td style={{ border: '1px solid #ccc', padding: '10px' }}>
								Affiliates
							</td>
						</tr>
					</tbody>
				</table>
				<Space h="xs" />
				<Text>
					We do not use or disclose sensitive personal information for the
					purposes of inferring characteristics about you.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">User Generated Content</Text>
				<Space h="sm" />
				<Text>
					The Services may enable you to post product reviews and other
					user-generated content. If you choose to submit user generated content
					to any public area of the Services, this content will be public and
					accessible by anyone.
				</Text>
				<Space h="xs" />
				<Text>
					We do not control who will have access to the information that you
					choose to make available to others, and cannot ensure that parties who
					have access to such information will respect your privacy or keep it
					secure. We are not responsible for the privacy or security of any
					information that you make publicly available, or for the accuracy, use
					or misuse of any information that you disclose or receive from third
					parties.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">
					Third Party Websites and Links
				</Text>
				<Space h="sm" />
				<Text>
					Our Site may provide links to websites or other online platforms
					operated by third parties. If you follow links to sites not affiliated
					or controlled by us, you should review their privacy and security
					policies and other terms and conditions. We do not guarantee and are
					not responsible for the privacy or security of such sites, including
					the accuracy, completeness, or reliability of information found on
					these sites. Information you provide on public or semi-public venues,
					including information you share on third-party social networking
					platforms may also be viewable by other users of the Services and/or
					users of those third-party platforms without limitation as to its use
					by us or by a third party. Our inclusion of such links does not, by
					itself, imply any endorsement of the content on such platforms or of
					their owners or operators, except as disclosed on the Services.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">Children’s Data</Text>
				<Space h="sm" />
				<Text>
					The Services are not intended to be used by children, and we do not
					knowingly collect any personal information about children. If you are
					the parent or guardian of a child who has provided us with their
					personal information, you may contact us using the contact details set
					out below to request that it be deleted.
				</Text>
				<Space h="xs" />
				<Text>
					As of the Effective Date of this Privacy Policy, we do not have actual
					knowledge that we “share” or “sell” (as those terms are defined in
					applicable law) personal information of individuals under 16 years of
					age.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">
					Security and Retention of Your Information
				</Text>
				<Space h="sm" />
				<Text>
					Please be aware that no security measures are perfect or impenetrable,
					and we cannot guarantee “perfect security.” In addition, any
					information you send to us may not be secure while in transit. We
					recommend that you do not use unsecure channels to communicate
					sensitive or confidential information to us.
				</Text>
				<Text>
					How long we retain your personal information depends on different
					factors, such as whether we need the information to maintain your
					account, to provide the Services, comply with legal obligations,
					resolve disputes or enforce other applicable contracts and policies.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold mt-4">Your Rights and Choices</Text>
				<Space h="sm" />
				<Text>
					Depending on where you live, you may have some or all of the rights
					listed below in relation to your personal information. However, these
					rights are not absolute, may apply only in certain circumstances and,
					in certain cases, we may decline your request as permitted by law.
				</Text>
				<Space h="xs" />
				<List icon={<Text>&bull;</Text>}>
					<List.Item>
						<b>Right to Access / Know.</b> You may have a right to request
						access to personal information that we hold about you, including
						details relating to the ways in which we use and share your
						information.
					</List.Item>
					<List.Item>
						<b>Right to Delete.</b> You may have a right to request that we
						delete personal information we maintain about you.
					</List.Item>
					<List.Item>
						<b>Right to Correct.</b> You may have a right to request that we
						correct inaccurate personal information we maintain about you.
					</List.Item>
					<List.Item>
						<b>Right of Portability.</b> You may have a right to receive a copy
						of the personal information we hold about you and to request that we
						transfer it to a third party, in certain circumstances and with
						certain exceptions.
					</List.Item>
					<List.Item>
						<b>Restriction of Processing:</b> You may have the right to ask us
						to stop or restrict our processing of personal information.
					</List.Item>
					<List.Item>
						<b>Withdrawal of Consent:</b> Where we rely on consent to process
						your personal information, you may have the right to withdraw this
						consent.
					</List.Item>
					<List.Item>
						<b>Appeal:</b> You may have a right to appeal our decision if we
						decline to process your request. You can do so by replying directly
						to our denial.
					</List.Item>
					<List.Item>
						<b>Managing Communication Preferences:</b> We may send you
						promotional emails, and you may opt out of receiving these at any
						time by using the unsubscribe option displayed in our emails to you.
						If you opt out, we may still send you non-promotional emails, such
						as those about your account or orders that you have made.
					</List.Item>
				</List>
				<Space h="xs" />
				<Text>
					You may exercise any of these rights where indicated on our Site or by
					contacting us using the contact details provided below.
				</Text>
				<Space h="xs" />
				<Text>
					We will not discriminate against you for exercising any of these
					rights. We may need to collect information from you to verify your
					identity, such as your email address or account information, before
					providing a substantive response to the request. In accordance with
					applicable laws, You may designate an authorized agent to make
					requests on your behalf to exercise your rights. Before accepting such
					a request from an agent, we will require that the agent provide proof
					you have authorized them to act on your behalf, and we may need you to
					verify your identity directly with us. We will respond to your request
					in a timely manner as required under applicable law.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">Complaints</Text>
				<Space h="sm" />
				<Text>
					If you have complaints about how we process your personal information,
					please contact us using the contact details provided below. If you are
					not satisfied with our response to your complaint, depending on where
					you live you may have the right to appeal our decision by contacting
					us using the contact details set out below, or lodge your complaint
					with your local data protection authority.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">International Users</Text>
				<Space h="sm" />
				<Text>
					Please note that we may transfer, store, and process your personal
					information outside the country in which you reside, including in
					Southeast Asia, Japan, and the United States. Your personal
					information may also be handled by our staff, as well as third-party
					service providers and partners, located in various jurisdictions
				</Text>
				<Space h="xs" />
				<Text>
					When we transfer your personal information to another country, we take
					measures designed to ensure that your data is protected in accordance
					with applicable laws and standards. These measures may include
					implementing recognized international frameworks (such as the APEC
					Cross-Border Privacy Rules), using industry-standard contractual
					protections, or relying on legally recognized data transfer
					mechanisms. Where required by law, we will only transfer your personal
					information to countries that have been deemed to provide an adequate
					level of protection, or we will ensure that appropriate safeguards
					(such as contractual clauses or certifications) are in place. If you
					would like more information about the specific transfer mechanisms we
					rely on, please contact us using the information provided in this
					policy.
				</Text>
				<Space h="40px" />
				<Text className="text-xl font-bold">Contact Us</Text>
				<Space h="sm" />
				<Text>
					Should you have any questions about our privacy practices or this
					Privacy Policy, or if you would like to exercise any of the rights
					available to you, email us at contact@tcgunite.co or contact us at:{' '}
					<Anchor onClick={() => navigate(HomeRoutes.Home)}>
						{' '}
						https://www.tcgunite.co/contact-us
					</Anchor>
				</Text>
			</ResponsiveContainer>
			<Space className="h-28" />
		</ViewContainer>
	);
};

export default PrivacyPolicy;
