import { z } from 'zod';

export const GetRafflesParamsSchema = z.object({
	limit: z.number().int().positive(),
	currency_code: z.string(),
});
export type GetRafflesParamsSchemaType = z.infer<typeof GetRafflesParamsSchema>;

const GetRaffleCurrencySchema = z.object({
	code: z.string(),
	symbol: z.string(),
});
const GetRaffleSchema = z.object({
	code: z.string().max(128),
	name: z.string().max(100),
	raffle_tier: z.string(),
	prize_pool: z.number().int().nonnegative(),
	image_url: z.string().max(2048).nullable(),
	currency: GetRaffleCurrencySchema,
});
export const GetRafflesSchema = z.array(GetRaffleSchema);
export type GetRafflesSchemaType = z.infer<typeof GetRafflesSchema>;
