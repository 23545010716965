import { getRaffleDetails } from 'apis/raffles';
import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';
import {
	GetRaffleDetailsParamsSchemaType,
	GetRaffleDetailsSchemaType,
} from 'schemas/raffles/get_raffle_details.schema';

export const getRaffleDetailsRaffleCodeAtom = atom<string | null>(null);
export const getRaffleDetailsParamsAtom =
	atom<GetRaffleDetailsParamsSchemaType>({
		currency_code: 'SGD',
	});
export const getRaffleDetailsAtom = atomWithQuery<GetRaffleDetailsSchemaType>(
	(get) => ({
		queryKey: ['raffle-details', get(getRaffleDetailsRaffleCodeAtom)],
		queryFn: async () =>
			await getRaffleDetails(
				get(getRaffleDetailsRaffleCodeAtom),
				get(getRaffleDetailsParamsAtom)
			),
	})
);
