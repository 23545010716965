import { z } from 'zod';

const RaffleGroupEarlyBirdLaunchPreregistrationMilestonePrizeSchema = z
	.object({
		number_of_target_users: z.number().int(),
		prize_pool: z.number().int().nonnegative(),
		raffle_tier: z.string(),
	})
	.strict();

export const RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchema = z
	.object({
		number_of_users: z.number().int().nonnegative(),
		unlock_prize_pool: z.number().int().nonnegative(),
		unlock_number_of_users: z.number().int().nonnegative(),
		currency_symbol: z.string(),
		milestones: z.array(
			RaffleGroupEarlyBirdLaunchPreregistrationMilestonePrizeSchema
		),
	})
	.strict();
export type RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchemaType =
	z.infer<typeof RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchema>;
