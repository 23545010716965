import React from 'react';
import Home from 'views/Home';
import { HomeRoutes } from '../constants/Home.routes';
import AboutUs from 'views/AboutUs';
import PrivacyPolicy from 'views/PrivacyPolicy';
import Careers from 'views/Careers';

export const homeRoutes = [
	{
		name: 'Home',
		path: HomeRoutes.Home,
		element: <Home />,
	},
	{
		name: 'About Us',
		path: HomeRoutes.AboutUs,
		element: <AboutUs />,
	},
	{
		name: 'Privacy Policy',
		path: HomeRoutes.PrivacyPolicy,
		element: <PrivacyPolicy />,
	},
	{
		name: 'Careers',
		path: HomeRoutes.Careers,
		element: <Careers />,
	},
];
