import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';
import {
	GetRaffleGroupsParamsSchemaType,
	GetRaffleGroupsSchemaType,
} from '../../schemas/raffle_groups/get_raffle_groups.schema';
import { useGetRaffleGroups } from 'apis/raffle_group.api';
import {
	GetFeaturedRaffleGroupParamsSchemaType,
	GetFeaturedRaffleGroupSchemaType,
} from 'schemas/raffle_groups/get_featured_raffle_group.schema';

export const getRaffleGroupsParamsAtom = atom<GetRaffleGroupsParamsSchemaType>({
	limit: 3,
	currency_code: 'SGD',
});

export const getRaffleGroupsAtom = atomWithQuery<GetRaffleGroupsSchemaType>(
	(get) => ({
		queryKey: ['raffle-groups', get(getRaffleGroupsParamsAtom)],
		queryFn: async () =>
			await useGetRaffleGroups(get(getRaffleGroupsParamsAtom)),
	})
);

export const getFeaturedRaffleGroupParamsAtom =
	atom<GetFeaturedRaffleGroupParamsSchemaType>({
		limit: 1,
		currency_code: 'SGD',
	});
export const getFeaturedRaffleGroup =
	atomWithQuery<GetFeaturedRaffleGroupSchemaType>((get) => ({
		queryKey: ['featured-raffle', get(getFeaturedRaffleGroupParamsAtom)],
		queryFn: async () => {
			// TODO: Get featured raffle once implemented in future
			const resp = await useGetRaffleGroups(
				get(getFeaturedRaffleGroupParamsAtom)
			);

			if (!resp || resp.length < 1) {
				return null;
			}
			return resp[0];
		},
	}));
