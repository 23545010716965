import React, { useState } from 'react';
import { Box, Group, Text, Space, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import ExploreMoreRaffles from './ExploreMoreRaffles';
import ExploreMoreRaffleGroups from './ExploreMoreRaffleGroups';

enum ExploreMoreScope {
	INDIVIDUAL = 'individual',
	GROUP = 'group',
}

function mapExploreMoreScope(scope: ExploreMoreScope): string {
	const { t } = useTranslation();

	switch (scope) {
		case ExploreMoreScope.GROUP:
			return t('group');
		case ExploreMoreScope.INDIVIDUAL:
			return t('individual');
		default:
			return scope;
	}
}

const ExploreMore = () => {
	const { t } = useTranslation();
	const [scope, setScope] = useState(ExploreMoreScope.INDIVIDUAL);
	return (
		<Box>
			<Text className="text-primary-brilliant-blue xl:text-4xl md:text-2xl text-md font-['Bungee'] text-left">
				{t('explore_more_raffles')}
			</Text>
			<Space className="md:h-4 h-2" />
			<Group className="md:justify-end justify-start gap-2">
				<Text className="xl:text-sm md:text-xs text-[8px] text-left font-semibold">
					{t('view_as')}
				</Text>
				<Select
					id="raffle-view-as"
					defaultValue={ExploreMoreScope.INDIVIDUAL}
					data={Object.values(ExploreMoreScope).map((scope) => {
						return { label: mapExploreMoreScope(scope), value: scope };
					})}
					onChange={(value) => {
						if (!value) return;
						setScope(value as ExploreMoreScope);
					}}
				/>
			</Group>
			<Space className="h-4" />
			{scope === ExploreMoreScope.GROUP ? (
				<ExploreMoreRaffleGroups />
			) : (
				<ExploreMoreRaffles />
			)}
		</Box>
	);
};

export default ExploreMore;
