import React, { useEffect, useState } from 'react';
import {
	Box,
	Group,
	Stack,
	Text,
	Image,
	Space,
	Flex,
	ScrollArea,
} from '@mantine/core';
import {
	formatCapitalizeFirstCharacter,
	formatNumberWithCommas,
	formatPrice,
} from 'utils/formatter';
import { Trans, useTranslation } from 'react-i18next';
import smallPrismaticTicketImage from 'views/assets/raffle/small_prismatic_ticket.png';
import smallGoldTicketImage from 'views/assets/raffle/small_gold_ticket.png';
import smallSilverTicketImage from 'views/assets/raffle/small_silver_ticket.png';
import { RaffleTierEnum } from 'utils/enums/raffle.enum';
import accountRegistrationImage from 'views/assets/shared/account_registration.png';
import {
	RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchema,
	RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchemaType,
} from 'schemas/raffle_groups/early_bird_launch/raffle_group_early_bird_launch_preregistration_milestones.schema';
import { db } from 'lib/firebase';
import { onValue, ref } from 'firebase/database';

const RaffleGroupEarlyBirdLaunchPrizePreregistrationMilestones = () => {
	const { t } = useTranslation();

	const initialState: RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchemaType =
		{
			number_of_users: 0,
			unlock_prize_pool: 0,
			unlock_number_of_users: 0,
			currency_symbol: 'S$',
			milestones: [],
		};
	const [pregistration, setPreregistration] =
		useState<RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchemaType>(
			initialState
		);

	useEffect(() => {
		const query = ref(
			db,
			'raffle_groups/early_bird_launch/preregistration_milestones'
		);
		return onValue(query, (snapshot) => {
			const data = snapshot.val();
			if (snapshot.exists()) {
				const schemaResult =
					RaffleGroupEarlyBirdLaunchPreregistrationMilestoneSchema.safeParse(
						data
					);

				if (schemaResult.success) {
					setPreregistration(schemaResult.data);
				} else {
					// TODO: Log firebase realtime database retrieve error
				}
			}
		});
	}, []);

	const lastMilestone =
		pregistration.milestones.length > 0
			? pregistration.milestones[pregistration.milestones.length - 1]
			: null;

	let percentageOfCompleteness = 0;

	if (lastMilestone) {
		percentageOfCompleteness =
			(pregistration.number_of_users / lastMilestone.number_of_target_users) *
			100;

		if (pregistration.number_of_users > lastMilestone.number_of_target_users) {
			percentageOfCompleteness = 100;
		}

		if (percentageOfCompleteness > 0 && percentageOfCompleteness < 4) {
			percentageOfCompleteness = 4;
		}
	}

	const progressWidthPercentage = 'w-[80%]';

	const isOverflowWidth =
		pregistration.number_of_users > (lastMilestone?.number_of_target_users ?? 0)
			? 'w-full'
			: progressWidthPercentage;

	return (
		<Box className="bg-primary-white-200 md:py-20 py-10 max-w-none shadow-inner xl:rounded-[5rem] rounded-[4rem] shadow-primary-white">
			<Flex className="md:flex-row md:px-12 px-6 flex-col justify-center align-items-center xl:gap-12">
				<Image
					src={accountRegistrationImage}
					alt="Prismatic Ticket"
					fit="contain"
					className="md:w-56 w-28"
				/>
				<Stack className="xl:align-items-center md:align-items-start align-items-center w-full">
					<Text className="text-primary-white md:text-4xl text-lg xl:text-center md:text-left text-center font-['Galindo']">
						{t('account_pregistration_milestone')}
					</Text>
					<Text
						className="text-primary-white xl:text-md md:text-sm text-[10px] font-['Galindo'] xl:text-center md:text-left text-center leading-none"
						id="raffle-group-early-bird-grand-launch-unlock-value"
					>
						<Trans
							i18nKey="unlock_up_to_value_in_value_of_prizes_in_additional_prizes_for_grand_launch"
							values={{
								prize_pool: formatPrice(
									pregistration.unlock_prize_pool,
									pregistration.currency_symbol,
									false
								),
							}}
						/>
					</Text>
				</Stack>
			</Flex>
			<ScrollArea className="w-full">
				<Box className="md:px-12 px-6">
					<Box className="xl:h-[340px] h-[280px] md:w-full w-[700px] relative">
						<Box className="bg-primary-white-800 w-full xl:h-[60px] h-[35px] relative rounded-tl-full rounded-bl-full top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] overflow-hidden">
							<Box
								className={`relative h-full ml-[0.25rem] ` + isOverflowWidth}
							>
								<Box
									w={percentageOfCompleteness + '%'}
									className="absolute bg-[#FFC753] inset-0 my-1 rounded-tl-full rounded-bl-full shadow-inner shadow-primary-white"
								></Box>
							</Box>

							<Stack className="justify-center absolute right-0 inset-y-0 pr-2 gap-0">
								<Text className="text-[#3470F0] text-right font-[Galindo] xl:text-md text-[10px] leading-none">
									{t('more_than')}
								</Text>
								<Space className="h-[3px]" />
								<Text className="text-[#3470F0] xl:text-xl text-md text-right font-[Galindo] leading-none">
									{formatNumberWithCommas(pregistration.unlock_number_of_users)}
								</Text>
							</Stack>
						</Box>
						<Box
							className={
								`absolute h-full top-1/2 translate-y-[-50%] ml-[0.25rem] ` +
								progressWidthPercentage
							}
						>
							{pregistration.milestones.map((milestone, index) => {
								const tierImage =
									milestone.raffle_tier === RaffleTierEnum.PRISMATIC
										? smallPrismaticTicketImage
										: milestone.raffle_tier === RaffleTierEnum.GOLD
										? smallGoldTicketImage
										: smallSilverTicketImage;

								const milestoneColor =
									pregistration.number_of_users >=
									milestone.number_of_target_users
										? 'bg-[#FFC753]'
										: 'bg-secondary-greyish-blue';

								const lastMilestone =
									pregistration.milestones[pregistration.milestones.length - 1];

								const leftPosition =
									(milestone.number_of_target_users /
										lastMilestone.number_of_target_users) *
									100;
								return (
									<Box
										key={index}
										left={leftPosition + '%'}
										className="absolute h-full inset-y-0"
									>
										<Stack
											key={index}
											className="flex-nowrap justify-center align-items-center h-full xl:gap-4 gap-2 absolute inset-0"
										>
											<Stack className="xl:h-[90px] h-[30px] justify-end">
												<Text className="text-primary-white xl:text-xl text-sm font-[Galindo]">
													{formatNumberWithCommas(
														milestone.number_of_target_users
													)}
												</Text>
											</Stack>
											<Box className="xl:h-[160px] h-[100px] xl:w-[30px] w-[20px] relative">
												<Box className="absolute bg-primary-white h-full xl:w-[10px] w-[8px] rounded-full inset-0 mx-auto" />
												<Box className="absolute bg-primary-white xl:h-[30px] h-[20px] xl:w-[30px] w-[20px] rounded-full bottom-0 flex justify-center align-items-center">
													<Box
														className={
															`rounded-full xl:w-[16px] w-[12px] xl:h-[16px] h-[12px] margin-auto ` +
															milestoneColor
														}
													/>
												</Box>
											</Box>
											<Box className="xl:h-[90px] h-[30px] xl:w-[110px] w-[80px]">
												<Text className="text-primary-white xl:text-sm text-[10px] font-[Galindo] text-center leading-none">
													{formatCapitalizeFirstCharacter(
														milestone.raffle_tier
													) + ' Tier'}
												</Text>
												<Space h="sm" />
												<Box className="bg-[#FFF8C2] xl:py-2 py-1 xl:px-1 px-0">
													<Group className="xl:gap-2 gap-1 align-items-center justify-center">
														<Image
															src={tierImage}
															alt="Prismatic Ticket"
															fit="contain"
															className="xl:w-6 w-4"
														/>
														<Text className="xl:text-xs text-[8px] text-[#3470F0] font-[Galindo] leading-none">
															+{' '}
															{formatPrice(
																milestone.prize_pool,
																pregistration.currency_symbol,
																false
															)}
														</Text>
													</Group>
												</Box>
											</Box>
										</Stack>
									</Box>
								);
							})}
						</Box>
					</Box>
				</Box>
			</ScrollArea>
		</Box>
	);
};

export default RaffleGroupEarlyBirdLaunchPrizePreregistrationMilestones;
