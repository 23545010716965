import React from 'react';
import { Box, Text } from '@mantine/core';

type RaffleGroupEarlyBirdLaunchPillTitleProps = {
	text: string;
};

const RaffleGroupEarlyBirdLaunchTitlePill = ({
	text,
}: RaffleGroupEarlyBirdLaunchPillTitleProps) => {
	return (
		<Box className="bg-[#3470F0] md:py-2 py-1 px-12 rounded-full w-fit mx-auto my-0">
			<Text className="text-primary-white md:text-3xl text-sm font-['Galindo'] text-center">
				{text}
			</Text>
		</Box>
	);
};

export default RaffleGroupEarlyBirdLaunchTitlePill;
