import React, { useEffect, useState } from 'react';
import {
	Box,
	Group,
	Stack,
	Text,
	Image,
	Space,
	Anchor,
	Flex,
	ScrollArea,
} from '@mantine/core';
import {
	formatCapitalizeFirstCharacter,
	formatNumberWithCommas,
	formatPrice,
} from 'utils/formatter';
import { useTranslation } from 'react-i18next';
import smallPrismaticTicketImage from '../../../../assets/raffle/small_prismatic_ticket.png';
import smallGoldTicketImage from '../../../../assets/raffle/small_gold_ticket.png';
import smallSilverTicketImage from '../../../../assets/raffle/small_silver_ticket.png';
import { RaffleTierEnum } from 'utils/enums/raffle.enum';
import youtubeImage from '../../../../assets/raffle_group/early_bird_launch/youtube.png';
import tiktokImage from '../../../../assets/raffle_group/early_bird_launch/tiktok.png';
import instagramImage from '../../../../assets/raffle_group/early_bird_launch/instagram.png';
import { onValue, ref } from 'firebase/database';
import { db } from 'lib/firebase';
import {
	RaffleGroupEarlyBirdLaunchSocialMediaMilestonesSchema,
	RaffleGroupEarlyBirdLaunchSocialMediaMilestonesSchemaType,
} from 'schemas/raffle_groups/early_bird_launch/raffle_group_early_bird_launch_social_media_milestones.schema';

const RaffleGroupEarlyBirdLaunchPrizeSocialMediaMilestones = () => {
	const { t } = useTranslation();
	const [milestones, setMilestones] =
		useState<RaffleGroupEarlyBirdLaunchSocialMediaMilestonesSchemaType | null>(
			null
		);

	useEffect(() => {
		const query = ref(
			db,
			'raffle_groups/early_bird_launch/social_media_milestones'
		);
		return onValue(query, (snapshot) => {
			const data = snapshot.val();
			if (snapshot.exists()) {
				const schemaResult =
					RaffleGroupEarlyBirdLaunchSocialMediaMilestonesSchema.safeParse(data);
				if (schemaResult.success) {
					setMilestones(schemaResult.data);
				} else {
					// TODO: Log firebase realtime database retrieve error
				}
			}
		});
	}, []);

	return (
		<Box className="bg-primary-white-200 xl:py-20 md:py-12 py-10 max-w-none shadow-inner xl:rounded-[5rem] rounded-[3rem] shadow-primary-white">
			<Stack className="align-items-center w-full md:px-12 px-6 ">
				<Text className="text-primary-white md:text-4xl text-lg text-center font-['Galindo']">
					{t('social_media_milestones')}
				</Text>
				<Text
					className="text-primary-white md:text-sm text-[10px] font-['Galindo'] text-center w-[400px] text-center"
					id="raffle-group-early-bird-grand-launch-unlock-value"
				>
					{t('subscribe_and_follow_to_unlock_more_prizes')}
				</Text>
			</Stack>
			<Space h="md" />
			{milestones?.map((milestone, index) => {
				const socialMediaImage =
					milestone.social_media.toLowerCase() === 'youtube'
						? youtubeImage
						: milestone.social_media.toLowerCase() === 'instagram'
						? instagramImage
						: tiktokImage;

				const lastPrize = milestone.prizes[milestone.prizes.length - 1];

				let percentageOfCompleteness =
					(milestone.number_of_users / lastPrize.number_of_target_users) * 100;

				if (milestone.number_of_users > lastPrize.number_of_target_users) {
					percentageOfCompleteness = 100;
				}

				const progressWidthPercentage = 'w-[90%]';

				const isOverflowWidth =
					milestone.number_of_users > lastPrize.number_of_target_users
						? 'w-full'
						: progressWidthPercentage;

				const followerName =
					milestone.social_media.toLowerCase() === 'youtube'
						? t('subscribers')
						: t('followers');

				const buttonName =
					milestone.social_media.toLowerCase() === 'youtube'
						? t('subscribe_now')
						: t('follow_now');

				return (
					<Stack key={index} className="align-items-center gap-0 py-4">
						<Flex className="md:flex-row flex-col flex-nowrap w-full md:px-12 px-0">
							<Stack className="gap-0 md:flex hidden justify-center relative w-[100px] xl:pt-8">
								<Text className="absolute xl:top-10 md:top-5 font-[Galindo] xl:text-3xl md:text-2xl text-primary-white">
									{milestone.social_media}
								</Text>
								<Text className="font-[Galindo] xl:text-sm text-xs text-primary-white">
									{followerName}
								</Text>
								<Image
									src={socialMediaImage}
									alt="Social Media"
									fit="contain"
									className="xl:w-20 w-12"
								/>
								<Space h="sm" />
								<Anchor
									href={milestone.social_media_url}
									target="_blank"
									className="no-underline xl:block hidden"
								>
									<Box className="bg-gold rounded-full shadow-inner shadow-white w-[150px] h-[40px] flex justify-center align-items-center">
										<Text className="font-semibold text-primary-white text-sm text-center font-['Galindo']">
											{buttonName}
										</Text>
									</Box>
								</Anchor>
							</Stack>
							<Group className="md:hidden flex flex-nowrap align-items-center px-6">
								<Image
									src={socialMediaImage}
									alt="Social Media"
									fit="contain"
									className="w-14"
								/>
								<Stack className="gap-0.5">
									<Text className="font-[Galindo] text-lg text-primary-white leading-none">
										{milestone.social_media}
									</Text>
									<Text className="font-[Galindo] text-sm text-primary-white leading-none">
										{followerName}
									</Text>
								</Stack>
							</Group>
							<ScrollArea className="w-full">
								<Box className="md:px-0 px-6">
									<Box className="xl:h-[280px] md:h-[180px] h-[160px] relative md:w-full w-[480px]">
										<Box className="bg-primary-white-800 w-full xl:h-[30px] h-[20px] relative rounded-tl-full rounded-bl-full top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] overflow-hidden">
											<Box
												className={
													`relative h-full ml-[0.25rem] ` + isOverflowWidth
												}
											>
												<Box
													w={percentageOfCompleteness + '%'}
													className="absolute bg-[#5CDCFF] inset-0 xl:my-1.5 my-[0.15rem] rounded-tl-full rounded-bl-full shadow-inner shadow-primary-white"
												></Box>
											</Box>
										</Box>
										<Box
											className={
												`absolute h-full top-1/2 translate-y-[-50%] ml-[0.25rem] ` +
												progressWidthPercentage
											}
										>
											{milestone.prizes.map((prize, index) => {
												const milestoneColor =
													milestone.number_of_users >=
													prize.number_of_target_users
														? 'bg-[#5CDCFF]'
														: 'bg-secondary-greyish-blue';

												const raffleTierImage =
													prize.raffle_tier === RaffleTierEnum.PRISMATIC
														? smallPrismaticTicketImage
														: prize.raffle_tier === RaffleTierEnum.GOLD
														? smallGoldTicketImage
														: smallSilverTicketImage;

												const leftPosition =
													((index + 1) / milestone.prizes.length) * 100;

												return (
													<Box
														key={index}
														left={leftPosition + '%'}
														className="absolute h-full inset-y-0"
													>
														<Stack className="flex-nowrap justify-center align-items-center h-full xl:gap-3 gap-0 absolute inset-0">
															<Stack className="xl:h-[80px] h-[20px] justify-end">
																<Text className="text-primary-white xl:text-xl text-sm font-[Galindo]">
																	{formatNumberWithCommas(
																		prize.number_of_target_users
																	)}
																</Text>
															</Stack>
															<Stack className="xl:h-[60px] h-[40px] w-[60px] relative justify-center align-items-center">
																<Box className="absolute bg-primary-white xl:h-[40px] h-[30px] xl:w-[40px] w-[30px] rounded-full flex justify-center align-items-center">
																	<Box
																		className={
																			`rounded-full xl:w-[22px] w-[18px] xl:h-[22px] h-[18px] margin-auto ` +
																			milestoneColor
																		}
																	/>
																</Box>
															</Stack>
															<Box className="xl:h-[80px] h-[20px] xl:w-[110px] w-[60px]">
																<Text className="text-primary-white xl:text-sm text-[8px] font-[Galindo] text-center">
																	{formatCapitalizeFirstCharacter(
																		prize.raffle_tier
																	) + ' Tier'}
																</Text>
																<Space h="sm" />
																<Box className="bg-[#FFF8C2] xl:py-2 py-1 xl:px-1 px-0.5">
																	<Group className="gap-2 align-items-center justify-center">
																		<Image
																			src={raffleTierImage}
																			alt="Prismatic Ticket"
																			fit="contain"
																			className="xl:w-6 w-4"
																		/>
																		<Text className="xl:text-xs text-[8px] text-[#3470F0] font-[Galindo] leading-none">
																			+{' '}
																			{formatPrice(
																				prize.prize_pool,
																				prize.currency_symbol,
																				false
																			)}
																		</Text>
																	</Group>
																</Box>
															</Box>
														</Stack>
													</Box>
												);
											})}
										</Box>
									</Box>
								</Box>
							</ScrollArea>
						</Flex>
						<Space className="md:hidden block h-[10px]" />
						<Anchor
							href={milestone.social_media_url}
							target="_blank"
							className="no-underline xl:hidden block"
						>
							<Box className="bg-gold rounded-full shadow-inner shadow-white w-[150px] h-[30px] flex justify-center align-items-center">
								<Text className="font-semibold text-primary-white text-xs text-center font-['Galindo']">
									{buttonName}
								</Text>
							</Box>
						</Anchor>
					</Stack>
				);
			})}
		</Box>
	);
};

export default RaffleGroupEarlyBirdLaunchPrizeSocialMediaMilestones;
