import React from 'react';
import {
	Box,
	Group,
	ScrollArea,
	Stack,
	Text,
	Image,
	Grid,
	UnstyledButton,
} from '@mantine/core';
import { formatPrice } from 'utils/formatter';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RaffleRoutes } from 'router/constants/Raffle.routes';
import smallSilverTicketImage from '../../assets/raffle/small_silver_ticket.png';
import { useAtom } from 'jotai';
import { getRafflesAtom } from 'atoms/raffles/getRafflesAtom';
import { mapSmallRaffleTierPlaceholderImage } from 'mappers/mapSmallRaffleTierPlaceholderImage';
import { mapSmallRaffleTierTicketImage } from 'mappers/mapSmallRaffleTierTicketImage';

const ExploreMoreRaffles = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [{ data: raffles }] = useAtom(getRafflesAtom);

	return (
		<>
			<Grid className="md:flex hidden">
				{raffles &&
					raffles.map((raffle, index) => {
						return (
							<Grid.Col span={4} key={index}>
								<UnstyledButton
									onClick={() =>
										navigate(
											RaffleRoutes.RaffleDetails.replace(
												':raffleCode',
												raffle.code
											)
										)
									}
									className="w-full"
								>
									<Box className="bg-primary-white drop-shadow-md rounded-xl overflow-hidden">
										<Stack className="gap-0">
											<Box id="explore-more-image">
												<Image
													src={
														raffle.image_url ??
														mapSmallRaffleTierPlaceholderImage(
															raffle.raffle_tier
														)
													}
													height="100%"
													alt="Raffle Image"
													fit="cover"
												/>
											</Box>
											<Stack className="xl:py-4 xl:px-4 py-3 px-2.5 xl:gap-3 gap-2">
												<Text className="xl:text-2xl text-md text-left font-semibold">
													{raffle.name}
												</Text>
												<Group className="items-center xl:gap-2 gap-1">
													<Image
														src={mapSmallRaffleTierTicketImage(
															raffle.raffle_tier
														)}
														alt="Ticket Image"
														fit="contain"
														className="xl:w-6 w-4"
													/>
													<Box className="bg-primary-brilliant-blue xl:w-[5px] w-[3px] xl:h-[5px] h-[3px] rounded-xs"></Box>
													<Text className="xl:text-sm text-[8px] text-primary-brilliant-blue leading-none">
														{t('current_total_prize_amount', {
															total_prize_pool: formatPrice(
																raffle.prize_pool,
																raffle.currency.symbol
															),
														})}
													</Text>
												</Group>
											</Stack>
										</Stack>
									</Box>
								</UnstyledButton>
							</Grid.Col>
						);
					})}
			</Grid>
			<ScrollArea w={'100%'} id="raffles-scroll-area" className="md:hidden">
				<Group className="flex-nowrap align-items-start">
					{raffles &&
						raffles.map((raffle, index) => {
							return (
								<UnstyledButton
									key={index}
									onClick={() => navigate(RaffleRoutes.RaffleDetails)}
								>
									<Box className="bg-primary-white drop-shadow-md rounded-xl overflow-hidden w-40">
										<Stack className="gap-0">
											<Box id="explore-more-image">
												<Image
													src={
														raffle.image_url ??
														mapSmallRaffleTierPlaceholderImage(
															raffle.raffle_tier
														)
													}
													height="100%"
													alt="Raffle Image"
													fit="cover"
												/>
											</Box>
											<Stack className="py-3 px-2.5 gap-2">
												<Text className="text-[10px] text-left font-semibold">
													{raffle.name}
												</Text>
												<Group className="align-items-center gap-1">
													<Image
														src={smallSilverTicketImage}
														alt="Ticket Image"
														fit="contain"
														className="w-3"
													/>
													<Box className="bg-primary-brilliant-blue w-[2px] h-[2px] rounded-xs"></Box>
													<Text className="text-[6px] text-primary-brilliant-blue leading-none">
														{t('current_total_prize_amount', {
															total_prize_pool: formatPrice(
																raffle.prize_pool,
																raffle.currency.symbol
															),
														})}
													</Text>
												</Group>
											</Stack>
										</Stack>
									</Box>
								</UnstyledButton>
							);
						})}
				</Group>
			</ScrollArea>
		</>
	);
};

export default ExploreMoreRaffles;
