import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../App';
import { homeRoutes } from './routes/homeRoutes';
import { errorRoutes } from './routes/errorRoutes';
import { raffleRoutes } from './routes/raffleRoutes';
import { raffleGroupRoutes } from './routes/raffleGroupRoutes';

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			...homeRoutes,
			...raffleRoutes,
			...raffleGroupRoutes,
			...errorRoutes,
		],
	},
]);

const Router = () => {
	return <RouterProvider router={router} />;
};

export default Router;
