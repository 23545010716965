import React, { useEffect, useState } from 'react';
import { Box, Group, Stack, Text, Image, Space } from '@mantine/core';
import {
	formatCapitalizeEveryFirstCharacter,
	formatPrice,
} from 'utils/formatter';
import { Trans, useTranslation } from 'react-i18next';
import { RaffleTierEnum } from 'utils/enums/raffle.enum';
import RaffleGroupEarlyBirdLaunchPrize from './RaffleGroupEarlyBirdLaunchPrize';
import mediumPrismaticTicketImage from '../../../../assets/raffle/medium_prismatic_ticket.png';
import { onValue, ref } from 'firebase/database';
import { db } from 'lib/firebase';
import {
	RaffleGroupEarlyBirdLaunchSchema,
	RaffleGroupEarlyBirdLaunchSchemaType,
} from 'schemas/raffle_groups/early_bird_launch/raffle_group_early_bird_launch.schema';

const RaffleGroupEarlyBirdLaunchPrizes = () => {
	const { t } = useTranslation();

	const [earlyBirdLaunch, setEarlyBirdLaunch] =
		useState<RaffleGroupEarlyBirdLaunchSchemaType | null>(null);

	useEffect(() => {
		const query = ref(db, 'raffle_groups/early_bird_launch');
		return onValue(query, (snapshot) => {
			const data = snapshot.val();
			if (snapshot.exists()) {
				const schemaResult = RaffleGroupEarlyBirdLaunchSchema.safeParse(data);

				if (schemaResult.success) {
					setEarlyBirdLaunch(schemaResult.data);
				} else {
					// TODO: Log firebase realtime database retrieve error
				}
			}
		});
	}, []);

	let prismaticPrizePool = 0;
	let silverPrizePool = 0;
	let goldPrizePool = 0;

	let prismaticUnlockPrizePool = 0;
	let silverUnlockPrizePool = 0;
	let goldUnlockPrizePool = 0;

	if (earlyBirdLaunch) {
		let prismaticPreregistrationPrizePool = 0;
		let silverPreregistrationPrizePool = 0;
		let goldPreregistrationPrizePool = 0;

		let prismaticSocialMediaPrizePool = 0;
		let silverSocialMediaPrizePool = 0;
		let goldSocialMediaPrizePool = 0;

		const preregistrationNumberOfUser =
			earlyBirdLaunch.preregistration_milestones.number_of_users;

		for (
			let i = 0;
			i < earlyBirdLaunch.preregistration_milestones.milestones.length;
			i++
		) {
			const milestone =
				earlyBirdLaunch.preregistration_milestones.milestones[i];

			if (preregistrationNumberOfUser >= milestone.number_of_target_users) {
				if (milestone.raffle_tier === RaffleTierEnum.PRISMATIC) {
					prismaticPreregistrationPrizePool +=
						earlyBirdLaunch.preregistration_milestones.milestones[i].prize_pool;
				} else if (milestone.raffle_tier === RaffleTierEnum.GOLD) {
					goldPreregistrationPrizePool +=
						earlyBirdLaunch.preregistration_milestones.milestones[i].prize_pool;
				} else if (milestone.raffle_tier === RaffleTierEnum.SILVER) {
					silverPreregistrationPrizePool +=
						earlyBirdLaunch.preregistration_milestones.milestones[i].prize_pool;
				}
			}
		}

		for (let i = 0; i < earlyBirdLaunch.social_media_milestones.length; i++) {
			const socialMedia = earlyBirdLaunch.social_media_milestones[i];

			for (let v = 0; v < socialMedia.prizes.length; v++) {
				const prize = socialMedia.prizes[v];

				if (socialMedia.number_of_users >= prize.number_of_target_users) {
					if (prize.raffle_tier === RaffleTierEnum.PRISMATIC) {
						prismaticSocialMediaPrizePool += prize.prize_pool;
					} else if (prize.raffle_tier === RaffleTierEnum.GOLD) {
						goldSocialMediaPrizePool += prize.prize_pool;
					} else if (prize.raffle_tier === RaffleTierEnum.SILVER) {
						silverSocialMediaPrizePool += prize.prize_pool;
					}
				}
			}
		}

		prismaticPrizePool =
			earlyBirdLaunch.raffles.prismatic_tier.prize_pool +
			prismaticPreregistrationPrizePool +
			prismaticSocialMediaPrizePool;

		silverPrizePool =
			earlyBirdLaunch.raffles.silver_tier.prize_pool +
			silverPreregistrationPrizePool +
			silverSocialMediaPrizePool;

		goldPrizePool =
			earlyBirdLaunch.raffles.gold_tier.prize_pool +
			goldPreregistrationPrizePool +
			goldSocialMediaPrizePool;

		prismaticUnlockPrizePool =
			earlyBirdLaunch.raffles.prismatic_tier.unlock_prize_pool;
		silverUnlockPrizePool =
			earlyBirdLaunch.raffles.silver_tier.unlock_prize_pool;
		goldUnlockPrizePool = earlyBirdLaunch.raffles.gold_tier.unlock_prize_pool;
	}
	return (
		<Box>
			<Box className="bg-primary-white-200 md:px-12 px-6 md:py-20 py-10 max-w-none shadow-inner xl:rounded-[5rem] rounded-[3rem] shadow-primary-white">
				<Stack className="flex-nowrap items-center gap-0">
					<Image
						src={mediumPrismaticTicketImage}
						alt="Prismatic Ticket"
						fit="contain"
						className="md:w-48 w-28"
					/>
					<Text className="text-[#FFF8C2] md:text-3xl text-xl text-center font-['Galindo']">
						Prismatic Tier
					</Text>
					<Space className="md:h-[60px] h-[30px]"></Space>
					<Text className="text-primary-white md:text-9xl text-6xl font-['Galindo'] text-center">
						{formatPrice(prismaticPrizePool, '$', false)}
					</Text>
					<Space className="md:h-[20px] h-[10px]"></Space>
					<Text className="text-primary-white md:text-3xl text-lg text-center font-['Galindo']">
						{formatCapitalizeEveryFirstCharacter(t('current_prize_pool'))}
					</Text>
					<Text
						className="text-primary-white text-sm font-['Galindo'] text-center md:block hidden"
						id="raffle-group-early-bird-grand-launch-unlock-value"
					>
						<Trans
							i18nKey="unlock_up_to_value_in_value_of_prizes"
							values={{
								prize_pool: formatPrice(prismaticUnlockPrizePool, '$', false),
							}}
						/>
					</Text>
				</Stack>
			</Box>
			<Space h="md" />
			<Group grow className="flex-nowrap md:gap-4 gap-3">
				<RaffleGroupEarlyBirdLaunchPrize
					raffle_tier={RaffleTierEnum.SILVER}
					prize_pool={silverPrizePool}
					unlock_prize_pool={silverUnlockPrizePool}
					currency_symbol="$"
				/>
				<RaffleGroupEarlyBirdLaunchPrize
					raffle_tier={RaffleTierEnum.GOLD}
					prize_pool={goldPrizePool}
					unlock_prize_pool={goldUnlockPrizePool}
					currency_symbol="$"
				/>
			</Group>
		</Box>
	);
};

export default RaffleGroupEarlyBirdLaunchPrizes;
