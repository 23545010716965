import { webApi } from 'apis';
import {
	GetRaffleGroupsParamsSchemaType,
	GetRaffleGroupsSchema,
} from 'schemas/raffle_groups/get_raffle_groups.schema';

export async function useGetRaffleGroups(
	params: GetRaffleGroupsParamsSchemaType
) {
	const resp = await webApi
		.get(`/raffle-groups`, {
			params,
		})
		.then(({ data }) => data);

	const result = GetRaffleGroupsSchema.safeParse(resp);
	if (!result.success) {
		// TODO: Log response parse error
		return null;
	}

	return resp;
}
