import React from 'react';
import { Box, Text } from '@mantine/core';

type RaffleDetailsPillProps = {
	text: string;
};

const RaffleDetailsPill = ({ text }: RaffleDetailsPillProps) => {
	return (
		<Box className="bg-secondary-light-blue md:py-2 py-1 px-12 rounded-full w-fit mx-auto my-0">
			<Text className="text-primary-white md:text-2xl text-sm font-['Bungee'] font-bold text-center uppercase">
				{text}
			</Text>
		</Box>
	);
};

export default RaffleDetailsPill;
