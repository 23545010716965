import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enNs1 from './locales/en/ns1.json';
import jaNs1 from './locales/ja/ns1.json';

i18next.use(initReactI18next).init({
	debug: process.env.REACT_APP_DEPLOYMENT_ENV !== 'production',
	fallbackLng: 'en',
	defaultNS: 'ns1',
	resources: {
		en: {
			ns1: enNs1,
		},
		ja: {
			ns1: jaNs1,
		},
	},
	// if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
	// set returnNull to false (and also in the i18next.d.ts options)
	// returnNull: false,
});

export default i18next;
