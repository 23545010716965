import React, { useEffect } from 'react';
import {
	Image,
	Space,
	Box,
	Anchor,
	Breadcrumbs,
	Text,
	Group,
	Stack,
} from '@mantine/core';
import { ResponsiveContainer, ViewContainer } from 'components';
import rafflePrizesPlaceholderImage from '../../assets/raffle/raffle_prizes_placeholder.png';

import { Trans, useTranslation } from 'react-i18next';
import RaffleDetailsPill from './components/RaffleDetailsPill';
import { formatPrice } from 'utils/formatter';
import ExploreMore from '../components/ExploreMore';
import {
	getRaffleDetailsAtom,
	getRaffleDetailsRaffleCodeAtom,
} from 'atoms/raffles/getRaffleDetailsAtom';
import { useAtom, useSetAtom } from 'jotai';
import { mapRaffleTierPlaceholderImage } from 'mappers/mapRaffleTierPlaceholderImage';
import { RaffleRoutes } from 'router/constants/Raffle.routes';
import { useParams } from 'react-router-dom';

const RaffleDetails = () => {
	const { raffleCode } = useParams();
	const { t } = useTranslation();
	const setSelectedRaffleCode = useSetAtom(getRaffleDetailsRaffleCodeAtom);

	useEffect(() => {
		setSelectedRaffleCode(raffleCode ?? null);
	}, [raffleCode]);

	const [{ data: raffle }] = useAtom(getRaffleDetailsAtom);

	const prizeGroupsColors = [
		'text-primary-old-gold',
		'text-secondary-light-blue',
		'text-primary-brilliant-blue',
		'text-secondary-dark-blue',
		'text-secondary-black',
	];

	return (
		<ViewContainer title="Raffles">
			{raffle && (
				<>
					<Box className="max-w-[1440px] mx-auto my-0">
						<Image
							src={
								raffle.banner_image_url ??
								mapRaffleTierPlaceholderImage(raffle.raffle_tier)
							}
							alt="Raffle"
							fit="cover"
						/>
					</Box>
					<Space h="xl" />
					<ResponsiveContainer>
						<Breadcrumbs
							className="text-primary-brilliant-blue xl:text-lg md:text-sm text-xs text-center"
							id="raffle-details-breadcrumb"
						>
							{[
								{ title: 'Raffles', href: RaffleRoutes.Raffles },
								{ title: raffle.name, href: '#' },
							].map((item, index) => {
								return (
									<Anchor
										href={item.href}
										key={index}
										className="text-primary-brilliant-blue"
									>
										{item.title}
									</Anchor>
								);
							})}
						</Breadcrumbs>
					</ResponsiveContainer>
					<Space className="xl:h-28 md:h-20 h-8" />
					<ResponsiveContainer>
						<RaffleDetailsPill text={t('current_prize_pool')} />
						<Space className="md:h-8 h-6" />
						<Text className="text-secondary-dark-blue xl:text-9xl md:text-7xl text-4xl font-['Bungee'] font-bold text-center break-all">
							{formatPrice(raffle.prize_pool, raffle.currency.symbol)}
						</Text>
						{raffle.unlock_prize_pool && (
							<>
								<Space className="md:h-4 h-2" />
								<Text
									className="text-secondary-dark-blue xl:text-sm md:text-xs text-[10px] font-['Bungee'] text-center"
									id="raffle-details-unlock-value"
								>
									<Trans
										i18nKey="unlock_up_to_value_in_value_of_prizes"
										values={{
											prize_pool: formatPrice(
												raffle.unlock_prize_pool,
												raffle.currency.symbol
											),
										}}
									/>
								</Text>
							</>
						)}
					</ResponsiveContainer>
					<Space className="xl:h-28 md:h-20 h-12" />
					<ResponsiveContainer>
						<RaffleDetailsPill text={t('what_you_can_get')} />
						<Space className="md:h-8 h-6" />
						<Box className="bg-secondary-white relative overflow-hidden xl:pt-8">
							<Group className="flex-nowrap bg-secondary-white  align-items-start">
								<Stack className="md:p-10 p-4 xl:gap-4 gap-1">
									<Text className="text-gold xl:text-4xl md:text-4xl text-[12px] font-['Bungee'] font-bold text-left uppercase">
										{t('the_excitement_is_building_and_so_are_the_prizes')}
									</Text>
									<Text className="text-primary-brilliant-blue xl:text-md md:text-sm text-[6px] font-semibold text-left">
										-{t('trust_us_you_wont_want_to_miss_this')}
									</Text>
								</Stack>
								<Image
									src={rafflePrizesPlaceholderImage}
									alt="Raffle"
									fit="cover"
									className="xl:visible invisible xl:w-[800px] w-26"
								/>
							</Group>
							<Image
								src={rafflePrizesPlaceholderImage}
								alt="Raffle"
								fit="cover"
								className="xl:hidden absolute bottom-0 right-[-60px] md:w-80 w-44"
							/>
						</Box>
					</ResponsiveContainer>
					{raffle.prize_groups.length > 0 && (
						<>
							<Space className="xl:h-28 md:h-20 h-12" />
							<ResponsiveContainer>
								<RaffleDetailsPill text={t('winners')} />
								{raffle.prize_groups.map((prizeGroup, prizeGroupIndex) => {
									let widthCss = 'w-12/12';
									switch (prizeGroupIndex) {
										case 0:
											widthCss = 'w-5/12';
											break;
										case 1:
											widthCss = 'w-6/12';
											break;
										case 2:
											widthCss = 'w-8/12';
											break;
										case 3:
											widthCss = 'w-10/12';
											break;
										case 4:
											widthCss = 'w-12/12';
											break;
									}

									const nameColor =
										prizeGroupIndex > prizeGroupsColors.length
											? prizeGroupsColors[prizeGroupsColors.length - 1]
											: prizeGroupsColors[prizeGroupIndex];

									return (
										<Box
											key={prizeGroupIndex}
											className={
												`bg-secondary-white mx-auto md:p-6 p-3 rounded-3xl md:my-8 my-6 ` +
												widthCss
											}
										>
											<Text
												className={`${nameColor} md:text-3xl text-[10px] font-['Bungee'] font-bold text-center uppercase`}
											>
												{prizeGroup.name}
											</Text>
											<Text className="text-primary-brilliant-blue-300 md:text-xs text-[7px] font-bold text-center">
												{t('worth_amount_and_number_of_winners', {
													prize_pool: formatPrice(
														prizeGroup.prize_pool,
														raffle.currency.symbol
													),
													number_of_winners: prizeGroup.prizes.length,
												})}
											</Text>
											<Space className="md:h-12 h-4" />
											<Group className="justify-center md:gap-4 gap-2">
												{prizeGroup.prizes.map((prize, prizeIndex) => {
													// TODO: Redirect to user profile if winner exists
													return (
														<Box
															key={prizeIndex}
															className="bg-secondary-greyish-blue rounded-full md:w-20 md:h-20 h-10 w-10 overflow-hidden"
															id="raffle-details-prize-winner-image"
														>
															{prize.winner?.image_url && (
																<Image
																	src={prize.winner.image_url}
																	alt="Winner"
																	fit="cover"
																	height="100%"
																	className="h-full"
																/>
															)}
														</Box>
													);
												})}
											</Group>
										</Box>
									);
								})}
							</ResponsiveContainer>
						</>
					)}
				</>
			)}
			<Space className="xl:h-28 md:h-20 h-8" />
			<ResponsiveContainer>
				<ExploreMore />
			</ResponsiveContainer>
			<Space className="h-28" />
		</ViewContainer>
	);
};

export default RaffleDetails;
