import React from 'react';

import { ResponsiveContainer, Space, ViewContainer } from 'components';

import RaffleFaq from './components/RaffleFaq';
import ExploreMore from './components/ExploreMore';
import FeaturedRaffleGroup from './components/FeaturedRaffleGroup';

const Raffles = () => {
	return (
		<ViewContainer title="Raffles">
			<FeaturedRaffleGroup />
			<Space className="xl:h-28 md:h-20 h-8" />
			<ResponsiveContainer>
				<ExploreMore />
			</ResponsiveContainer>
			<Space className="xl:h-28 md:h-20 h-8" />
			<ResponsiveContainer>
				<RaffleFaq />
			</ResponsiveContainer>
			<Space className="h-28" />
		</ViewContainer>
	);
};

export default Raffles;
