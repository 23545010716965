import { webApi } from 'apis';
import {
	GetRafflesParamsSchemaType,
	GetRafflesSchema,
} from 'schemas/raffles/get_raffles.schema';

export async function getRaffles(params: GetRafflesParamsSchemaType) {
	const resp = await webApi
		.get(`/raffles`, {
			params,
		})
		.then(({ data }) => data);

	const result = GetRafflesSchema.safeParse(resp);
	if (!result.success) {
		// TODO: Log response parse error
		return null;
	}

	return resp;
}
