import React, { useEffect } from 'react';
import AppContainer from './layouts/AppContainer';
import ThemeProvider from 'providers/ThemeProvider';
import QueryProvider from 'providers/QueryProvider';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';

function App() {
	useEffect(() => {
		if (process.env.REACT_APP_GOOGLE_ANALYTIC_MEASUREMENT_ID) {
			ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_MEASUREMENT_ID);
		}
	}, []);

	return (
		<HelmetProvider>
			<QueryProvider>
				<ThemeProvider>
					<AppContainer />
				</ThemeProvider>
			</QueryProvider>
		</HelmetProvider>
	);
}

export default App;
