import { RaffleTier } from 'enums/raffle.enum';
import smallSilverRafflePlaceholderImage from 'views/assets/raffle/small_silver_raffle_placeholder.png';
import smallGoldRafflePlaceholderImage from 'views/assets/raffle/small_gold_raffle_placeholder.png';
import smallPrismaticRafflePlaceholderImage from 'views/assets/raffle/small_prismatic_raffle_placeholder.png';

export function mapSmallRaffleTierPlaceholderImage(
	raffle_tier: string
): string {
	switch (raffle_tier) {
		case RaffleTier.PRISMATIC:
			return smallPrismaticRafflePlaceholderImage;
		case RaffleTier.GOLD:
			return smallGoldRafflePlaceholderImage;
		case RaffleTier.SILVER:
			return smallSilverRafflePlaceholderImage;
		default:
			return smallSilverRafflePlaceholderImage;
	}
}
