import {
	Box,
	ViewContainer,
	Text,
	Image,
	ResponsiveContainer,
	Space,
	Group,
	Flex,
	Stack,
	UnstyledButton,
} from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import backdropMobileImage from '../../../assets/raffle_group/early_bird_launch/early_bird_details_backdrop_mobile.png';
import backdropTabletImage from '../../../assets/raffle_group/early_bird_launch/early_bird_details_backdrop_tablet.png';
import backdropDesktopImage from '../../../assets/raffle_group/early_bird_launch/early_bird_details_backdrop_desktop.png';
import earlyBirdHeroImage from '../../../assets/raffle_group/early_bird_launch/early_bird_hero.png';
import goldTicketsImage from '../../../assets/raffle_group/early_bird_launch/gold_tickets.png';
import silverTicketsImage from '../../../assets/raffle_group/early_bird_launch/silver_tickets.png';
import angledPrismaticTicketImage from '../../../assets/raffle_group/early_bird_launch/angled_prismatic_ticket.png';
import angledGoldTicketImage from '../../../assets/raffle_group/early_bird_launch/angled_gold_ticket.png';
import angledSilverTicketImage from '../../../assets/raffle_group/early_bird_launch/angled_silver_ticket.png';
import raffleTicketsImage from '../../../assets/shared/raffle_tickets.png';
import raffleBoardImage from '../../../assets/shared/raffle_board.png';
import raffleRewardsImage from '../../../assets/shared/raffle_rewards.png';
import accountRegistrationImage from '../../../assets/shared/account_registration.png';
import referFriendsImage from '../../../assets/shared/refer_friends.png';
import raffleLogoImage from '../../../assets/shared/raffle_logo.png';
import earlyBirdLogoImage from '../../../assets/raffle_group/early_bird_launch/early_bird_logo.png';

import { RaffleTierEnum } from 'utils/enums/raffle.enum';
import {
	formatCapitalizeEveryFirstCharacter,
	formatCapitalizeFirstCharacter,
} from 'utils/formatter';
import RaffleGroupEarlyBirdLaunchTitlePill from './components/RaffleGroupEarlyBirdLaunchTitlePill';
import RaffleGroupEarlyBirdLaunchPrizes from './components/RaffleGroupEarlyBirdLaunchPrizes';
import RaffleGroupEarlyBirdLaunchPrizePreregistrationMilestones from './components/RaffleGroupEarlyBirdLaunchPreregistrationMilestones';
import RaffleGroupEarlyBirdLaunchPrizeSocialMediaMilestones from './components/RaffleGroupEarlyBirdLaunchSocialMediaMilestones';
import RaffleFaq from 'views/Raffles/components/RaffleFaq';

const RaffleGroupEarlyBirdLaunchDetails = () => {
	const { t } = useTranslation();

	const pregistrationGifts = [
		{
			number_of_gifts: 1,
			raffle_tier: RaffleTierEnum.PRISMATIC,
		},
		{
			number_of_gifts: 3,
			raffle_tier: RaffleTierEnum.GOLD,
		},
		{
			number_of_gifts: 5,
			raffle_tier: RaffleTierEnum.SILVER,
		},
	];

	const howToUseTicket = [
		{
			text: t('collect_your_tickets'),
			image: raffleTicketsImage,
		},
		{
			text: t('enter_your_chosen_raffle'),
			image: raffleBoardImage,
		},
		{
			text: t('win_your_rewards'),
			image: raffleRewardsImage,
		},
	];

	const earnMoreTickets = [
		{
			text: t('sign_up_an_account'),
			image: accountRegistrationImage,
		},
		{
			text: t('refer_it_to_friends'),
			image: referFriendsImage,
		},
		{
			text: t('earn_more_ticket_when_they_sign_up'),
			image: raffleTicketsImage,
		},
	];

	return (
		<ViewContainer title="Home">
			<Box className="relative bg-background-blue">
				<img
					src={backdropDesktopImage}
					alt="Backdrop"
					className="absolute top-0 bottom-0 h-full w-full z-1 object-cover object-top xl:block hidden"
				/>
				<img
					src={backdropTabletImage}
					alt="Backdrop"
					className="absolute top-0 bottom-0 h-full w-full z-1 object-cover object-top xl:hidden md:block hidden"
				/>
				<img
					src={backdropMobileImage}
					alt="Backdrop"
					className="absolute top-0 bottom-0 h-full w-full z-1 object-cover object-top md:hidden block"
				/>
				<ResponsiveContainer className="z-10 relative md:py-12 py-8">
					<Box className="relative">
						<Image
							src={earlyBirdHeroImage}
							alt="Early Bird Launch Hero"
							fit="contain"
						/>
						<UnstyledButton className="w-full">
							<Box className="bg-gold rounded-full shadow-inner shadow-white xl:w-[400px] md:w-[300px] w-full md:h-[70px] h-[50px] md:absolute bottom-0 flex justify-center align-items-center">
								<Text className="font-semibold text-primary-white md:text-2xl text-md text-center font-['Galindo']">
									{t('preregister_now')}
								</Text>
							</Box>
						</UnstyledButton>
					</Box>
				</ResponsiveContainer>
				<ResponsiveContainer className="z-10 relative md:py-12 py-8">
					<Box className="bg-primary-white md:px-12 px-6 py-6 md:rounded-[5rem] rounded-[2rem]">
						<Text className="text-dark-blue md:text-4xl text-2xl xl:text-right text-center font-['Galindo']">
							{t('preregister_gifts')}
						</Text>
						<Space className="md:h-12 h-6" />
						<Flex className="md:flex-row flex-col md:justify-evenly gap-8 md:flex-wrap">
							{pregistrationGifts.map((gift, index) => {
								const ticketImage =
									gift.raffle_tier === RaffleTierEnum.PRISMATIC
										? angledPrismaticTicketImage
										: gift.raffle_tier === RaffleTierEnum.GOLD
										? goldTicketsImage
										: silverTicketsImage;
								return (
									<Group key={index} className="flex-nowrap md:gap-0 gap-4">
										<Image
											src={ticketImage}
											alt="Early Bird Launch Hero"
											fit="contain"
											className="md:w-44 w-44"
										/>
										<Box className="md:w-auto w-full">
											<Text className="text-secondary-dark-blue text-xl text-left font-['Galindo'] leading-none">
												{formatCapitalizeFirstCharacter(gift.raffle_tier)}
											</Text>
											<Text className="text-secondary-dark-blue text-xl text-left font-['Galindo'] leading-none">
												Ticket
											</Text>
											<Text className="text-secondary-dark-blue text-xl text-left font-['Galindo'] leading-none">
												x{gift.number_of_gifts}
											</Text>
										</Box>
									</Group>
								);
							})}
						</Flex>
						<Space className="h-[10px]" />
						<Text className="text-primary-brilliant-blue-500 text-sm text-center font-semibold">
							{t('terms_and_conditions_apply')}
						</Text>
					</Box>
				</ResponsiveContainer>
				<Space className="h-[50px]" />
				<ResponsiveContainer className="z-10 relative">
					<RaffleGroupEarlyBirdLaunchTitlePill
						text={t('what_is_the_raffle_ticket')}
					/>
					<Space h="lg" />
					<Box className="bg-primary-white-200 md:p-12 p-6 max-w-none shadow-inner md:rounded-[5rem] rounded-[3rem] shadow-primary-white">
						<Group
							className="flex-nowrap align-items-stretch xl:flex hidden"
							grow
						>
							<Box className="bg-primary-white rounded-[3rem] p-4 flex flex-col justify-center">
								<Text className="text-dark-blue text-2xl text-left font-[Galindo]">
									{t(
										'win_amazing_prizes_with_our_regular_3_tier_raffle_extravaganza'
									)}
								</Text>
								<Space h="md" />
								<Text className="text-dark-blue text-2xl font-[Galindo]">
									{t(
										'from_sealed_products_to_graded_slabs_you_can_win_them_all'
									)}
								</Text>
							</Box>
							<Box className="bg-primary-white rounded-[3rem] py-4 px-2">
								{[
									RaffleTierEnum.SILVER,
									RaffleTierEnum.GOLD,
									RaffleTierEnum.PRISMATIC,
								].map((tier, index) => {
									const flexDirection =
										index % 2 === 0 ? 'flex-row' : 'flex-row-reverse';
									const textAlign =
										index % 2 === 0 ? 'text-left' : 'text-center';

									const ticketImage =
										tier === RaffleTierEnum.PRISMATIC
											? angledPrismaticTicketImage
											: tier === RaffleTierEnum.GOLD
											? angledGoldTicketImage
											: angledSilverTicketImage;

									const textColor =
										tier === RaffleTierEnum.PRISMATIC
											? 'text-dark-blue'
											: tier === RaffleTierEnum.GOLD
											? 'text-primary-old-gold'
											: 'text-[#9EACD0]';
									return (
										<Flex
											key={index}
											className={
												`flex-nowrap gap-0 align-items-center gap-0 ` +
												flexDirection
											}
										>
											<Image
												src={ticketImage}
												alt={tier + ' ticket'}
												fit="contain"
												className="w-48"
											/>
											<Text
												className={
													`text-2xl font-[Galindo] ` +
													textAlign +
													' ' +
													textColor
												}
											>
												{t('raffle_ticket_for_raffle_tier_raffles', {
													raffle_tier: formatCapitalizeFirstCharacter(tier),
												})}
											</Text>
										</Flex>
									);
								})}
							</Box>
						</Group>
						<Stack className="xl:hidden">
							<Box className="bg-primary-white md:rounded-[3rem] rounded-[1rem] md:px-4 xl:py-4 md:py-10 p-3 flex flex-col justify-center">
								<Text className="text-dark-blue md:text-2xl text-xs text-center font-[Galindo]">
									{t(
										'win_amazing_prizes_with_our_regular_3_tier_raffle_extravaganza'
									)}
								</Text>
								<Space h="md" />
								<Text className="text-dark-blue md:text-2xl text-xs text-center font-[Galindo]">
									{t(
										'from_sealed_products_to_graded_slabs_you_can_win_them_all'
									)}
								</Text>
								<Space className="h-[30px]" />

								<Stack className="gap-0">
									{[
										RaffleTierEnum.SILVER,
										RaffleTierEnum.GOLD,
										RaffleTierEnum.PRISMATIC,
									].map((tier, index) => {
										const flexDirection =
											index % 2 === 0 ? 'flex-row' : 'md:flex-row-reverse';
										const textAlign =
											index % 2 === 0
												? 'text-left'
												: 'md:text-center text-left';

										const ticketImage =
											tier === RaffleTierEnum.PRISMATIC
												? angledPrismaticTicketImage
												: tier === RaffleTierEnum.GOLD
												? angledGoldTicketImage
												: angledSilverTicketImage;

										const textColor =
											tier === RaffleTierEnum.PRISMATIC
												? 'text-dark-blue'
												: tier === RaffleTierEnum.GOLD
												? 'text-primary-old-gold'
												: 'text-[#9EACD0]';
										return (
											<Flex
												key={index}
												className={
													`flex-nowrap gap-0 align-items-center md:gap-0 gap-2 ` +
													flexDirection
												}
											>
												<Image
													src={ticketImage}
													alt={tier + ' ticket'}
													fit="contain"
													className="md:w-48 w-20"
												/>
												<Text
													className={
														`md:text-2xl text-xs font-[Galindo] md:w-auto w-full ` +
														textAlign +
														' ' +
														textColor
													}
												>
													{t('raffle_ticket_for_raffle_tier_raffles', {
														raffle_tier: formatCapitalizeFirstCharacter(tier),
													})}
												</Text>
											</Flex>
										);
									})}
								</Stack>
							</Box>
						</Stack>
					</Box>
				</ResponsiveContainer>
				<Space className="h-[50px]" />
				<ResponsiveContainer className="z-10 relative">
					<RaffleGroupEarlyBirdLaunchTitlePill
						text={t('how_to_user_your_tickets')}
					/>
					<Space h="lg" />
					<Flex className="md:flex-row flex-col gap-3 flex-nowrap align-items-stretch justify-center">
						{howToUseTicket.map((howTo, index) => {
							return (
								<Flex
									key={index}
									className="md:flex-col md:basis-0 flex-row align-items-center grow bg-primary-white-800 xl:px-12 px-6 xl:py-16 md:py-10 py-2 max-w-none shadow-inner xl:rounded-[6rem] md:rounded-[3rem] rounded-[2rem] shadow-primary-white gap-0 md:h-auto h-36"
								>
									<Text
										className={`xl:text-4xl md:text-xl text-xl font-[Galindo] text-dark-blue xl:h-[70px] md:h-[50px] leading-none`}
									>
										{howTo.text}
									</Text>
									<Space className="xl:h-4 h-0" />
									<Image
										src={howTo.image}
										alt="Silver ticket"
										fit="contain"
										className="xl:w-64 md:w-40 w-24 mx-auto"
									/>
								</Flex>
							);
						})}
					</Flex>
				</ResponsiveContainer>
				<Space className="h-[50px]" />
				<ResponsiveContainer className="z-10 relative">
					<RaffleGroupEarlyBirdLaunchTitlePill
						text={formatCapitalizeEveryFirstCharacter(t('current_prize_pool'))}
					/>
					<Space h="lg" />
					<RaffleGroupEarlyBirdLaunchPrizes />
				</ResponsiveContainer>
				<Space className="h-[50px]" />
				<ResponsiveContainer className="relative z-10">
					<RaffleGroupEarlyBirdLaunchTitlePill
						text={t('increase_the_raffles_value')}
					/>
					<Space h="lg" />
					<RaffleGroupEarlyBirdLaunchPrizePreregistrationMilestones />
				</ResponsiveContainer>
				<Space className="h-[50px]" />
				<ResponsiveContainer className="relative z-10">
					<RaffleGroupEarlyBirdLaunchPrizeSocialMediaMilestones />
				</ResponsiveContainer>
				<Space className="h-[50px]" />
				<ResponsiveContainer className="relative z-10">
					<RaffleGroupEarlyBirdLaunchTitlePill text={t('earn_more_tickets')} />
					<Space h="lg" />
					<Flex className="md:flex-row flex-col gap-3 flex-nowrap align-items-stretch justify-center">
						{earnMoreTickets.map((earnMore, index) => {
							return (
								<Flex
									key={index}
									className="md:flex-col md:basis-0 flex-row align-items-center grow bg-primary-white-800 xl:px-12 px-6 xl:py-16 md:py-10 py-2 max-w-none shadow-inner xl:rounded-[6rem] md:rounded-[3rem] rounded-[2rem] shadow-primary-white gap-0 md:h-auto h-36"
								>
									<Text
										className={`xl:text-4xl md:text-xl text-xl font-[Galindo] text-dark-blue xl:h-[100px] md:h-[80px] leading-none`}
									>
										{earnMore.text}
									</Text>
									<Space className="xl:h-4 h-0" />
									<Image
										src={earnMore.image}
										alt="Silver ticket"
										fit="contain"
										className="xl:w-64 md:w-40 w-32 mx-auto"
									/>
								</Flex>
							);
						})}
					</Flex>
					<Space h="lg" />
					{/* TODO: Click to go refer page */}
					<UnstyledButton className="w-full">
						<Box className="bg-gold rounded-full shadow-inner shadow-white w-[200px] md:h-[70px] h-[60px] flex justify-center align-items-center mx-auto">
							<Text className="font-semibold text-primary-white md:text-2xl text-md text-center font-['Galindo']">
								{t('refer_now')}
							</Text>
						</Box>
					</UnstyledButton>
				</ResponsiveContainer>
				<Space className="h-[50px]" />
				<ResponsiveContainer className="z-10 relative md:py-12 py-8">
					<Image
						src={earlyBirdLogoImage}
						alt="Early Bird Logo Hero"
						fit="contain"
					/>
					<Space className="h-[35px]" />
					{/* TODO: Click to go register page */}
					<UnstyledButton className="w-full">
						<Box className="bg-gold rounded-full shadow-inner shadow-white md:w-[700px] w-full md:h-[70px] h-[60px] flex justify-center align-items-center mx-auto">
							<Text className="font-semibold text-primary-white md:text-2xl text-sm text-center font-['Galindo'] md:block hidden">
								{t('preregister_today_and_start_earning')}
							</Text>
							<Text className="font-semibold text-primary-white text-lg text-center font-['Galindo'] md:hidden block">
								{t('preregister_and_join_now')}
							</Text>
						</Box>
					</UnstyledButton>
				</ResponsiveContainer>
				<Box className="z-10 relative">
					<Box className="xl:h-[800px] md:h-[520px] h-[350px]">
						<Box className="xl:h-[600px] md:h-[320px] h-[100px]">
							<ResponsiveContainer className="absolute inset-0">
								<Image
									src={raffleLogoImage}
									alt="Raffle Logo Hero"
									fit="contain"
								/>
							</ResponsiveContainer>
						</Box>

						<Box className="md:h-[200px] h-[250px] bg-dark-blue">
							<Stack className="gap-0 align-items-center justify-end h-full py-4">
								<Text className="text-primary-white font-semibold">
									All Rights Reserved
								</Text>
								<Text className="text-primary-white font-semibold">
									Terms and Conditions Applies
								</Text>
								<Space h="lg" />
								<Text className="text-secondary-white text-sm hover:no-underline md:text-left text-center">
									@ 2024 TCG Unite
								</Text>
							</Stack>
						</Box>
					</Box>
				</Box>
			</Box>
			<Space className="md:h-[150px] h-[100px]" />
			<ResponsiveContainer>
				<RaffleFaq />
			</ResponsiveContainer>
			<Space className="h-[200px]" />
		</ViewContainer>
	);
};

export default RaffleGroupEarlyBirdLaunchDetails;
