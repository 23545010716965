import { getRaffles } from 'apis/raffles';
import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';
import {
	GetRafflesParamsSchemaType,
	GetRafflesSchemaType,
} from 'schemas/raffles/get_raffles.schema';

export const getRafflesParamsAtom = atom<GetRafflesParamsSchemaType>({
	limit: 3,
	currency_code: 'SGD',
});
export const getRafflesAtom = atomWithQuery<GetRafflesSchemaType>((get) => ({
	queryKey: ['raffles'],
	queryFn: async () => await getRaffles(get(getRafflesParamsAtom)),
}));
