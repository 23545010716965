import React from 'react';
import { RaffleRoutes } from 'router/constants/Raffle.routes';
import RaffleDetails from 'views/Raffles/RaffleDetails/RaffleDetails';
import Raffles from 'views/Raffles/Raffles';

export const raffleRoutes = [
	{
		name: 'Raffles',
		path: RaffleRoutes.Raffles,
		element: <Raffles />,
	},
	{
		name: 'Raffle',
		path: RaffleRoutes.RaffleDetails,
		element: <RaffleDetails />,
	},
];
