import { AppShell } from '@mantine/core';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Topbar from './Topbar';
import CustomFooter from './Footer';
import { HomeRoutes } from 'router/constants/Home.routes';

const AppContainer: React.FC = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		document.querySelector('body')?.scrollTo(0, 0);
	}, [pathname]);

	return (
		<AppShell
			header={
				pathname === HomeRoutes.Home ||
				location.pathname === HomeRoutes.AboutUs ? (
					<></>
				) : (
					<Topbar />
				)
			}
			footer={<CustomFooter />}
			padding="0"
			className={
				pathname === HomeRoutes.Home
					? 'bg-secondary-dark-blue'
					: location.pathname === HomeRoutes.AboutUs
					? 'bg-secondary-white'
					: ''
			}
		>
			<Outlet />
		</AppShell>
	);
};

export default AppContainer;
